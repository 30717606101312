// Services
import StyleService from "../../services/style.service";

// Exports
export const SET_STYLES = "SET_STYLES";

export const setStyles = (styles) => {
    return {
        type: SET_STYLES,
        styles: styles
    }
}

export const loadStyles = async (dispatch, getState) => {
    try {
      let styles = await StyleService.getAllStyles();
      
      styles = styles.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      
      dispatch({ type: SET_STYLES, styles: styles })
    } catch (err) {
      //
    }
}

const actions = {
    setStyles,
    loadStyles
}

export default actions