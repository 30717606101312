import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import useScrollPercentage from 'react-scroll-percentage-hook';

import debounce from 'lodash/debounce';

// Store
import actions from "../../store/actions";

// Services
import ImageService from "../../services/image.service";
import JobService from "../../services/job.service";
import AuthService from "../../services/auth.service";

// Components
import cx from "classnames";
import { Button } from "../../common/common";

import "./Generate.css";

import arrow_left_1 from "../../assets/icons/rewind.svg";
import arrow_right_1 from "../../assets/icons/forward.svg";
import arrow_left_2 from "../../assets/icons/arrow-left.svg";
import coin from "../../assets/icons/coin.svg"

import arrow_gallery_left from "../../assets/icons/circle-left.svg";
import arrow_gallery_right from "../../assets/icons/circle-right.svg";

import generating_placeholder from "../../assets/icons/generating_placeholder.png";

import one_image_view from "../../assets/icons/filter-view-single.svg";
import gallery_view from "../../assets/icons/filter-view-gallery.svg";
import phone_view from "../../assets/icons/filter-view-phone.svg";

import sort_asc from "../../assets/icons/sort-vertical-1.svg";
import sort_desc from "../../assets/icons/sort-vertical-2.svg";

import triangle_up from "../../assets/icons/triangle-up.svg";
import triangle_down from "../../assets/icons/triangle-down.svg";

// Phone view assets
// General iphone
import iphone_outline from "../../assets/icons/iphone-outline.png";
import iphone_header from "../../assets/icons/iphone_header.svg";

// Tinder
import tinder_header_left from "../../assets/icons/tinder/tinder_header_left.svg";
import tinder_header_middle from "../../assets/icons/tinder/tinder_header_middle.svg";
import tinder_header_right from "../../assets/icons/tinder/tinder_header_right.svg";
import tinder_buttons from "../../assets/icons/tinder/tinder_buttons.svg";
import tinder_footer from "../../assets/icons/tinder/tinder_footer.svg";

// Coffee Meets Bagel
import cmb_bean from "../../assets/icons/coffeemeetsbagel/cmb_bean.svg";
import cmb_briefcase from "../../assets/icons/coffeemeetsbagel/cmb_briefcase.svg";
import cmb_chat from "../../assets/icons/coffeemeetsbagel/cmb_chat.svg";
import cmb_chats from "../../assets/icons/coffeemeetsbagel/cmb_chats.svg";
import cmb_discover from "../../assets/icons/coffeemeetsbagel/cmb_discover.svg";
import cmb_heart from "../../assets/icons/coffeemeetsbagel/cmb_heart.svg";
import cmb_suggested from "../../assets/icons/coffeemeetsbagel/cmb_suggested.svg";
import cmb_x from "../../assets/icons/coffeemeetsbagel/cmb_x.svg";


const GalleryBar = ({progressEnabled = false, progress = 0.0, viewOptions, sortOptions, dateOptions}) => {

  const [viewFilterToggle, setViewFilterToggle] = useState(false);
  const [sortFilterToggle, setSortFilterToggle] = useState(false);
  const [dateFilterToggle, setDateFilterToggle] = useState(false);

  const toggleFilter = (i) => {
    switch(i) {
      case 0:
        setSortFilterToggle(false)
        setDateFilterToggle(false)
        toggleViewFilter()
        break;
      case 1:
        setViewFilterToggle(false)
        setDateFilterToggle(false)
        toggleSortFilter()
        break;
      case 2:
        setViewFilterToggle(false)
        setSortFilterToggle(false)
        toggleDateFilter()
        break
      default:
        break
    }
  }

  const toggleViewFilter = () => {
    setViewFilterToggle(!viewFilterToggle);
  }

  const toggleSortFilter = () => {
    setSortFilterToggle(!sortFilterToggle);
  }

  const toggleDateFilter = () => {
    setDateFilterToggle(!dateFilterToggle);
  }

  return (
    <div className="generate-gallery-bar">
      <div className="generate-gallery-bar-main">
        <div className="generate-gallery-bar-main-view-filter" onClick={() => {toggleFilter(0)}}>
          {viewFilterToggle ?
          <ul className="generate-gallery-bar-main-view-filter-list">
            {viewOptions.map((option, i) => (
              <li key={i} className="generate-gallery-bar-main-view-filter-list-item" onClick={() => option.onclick()}>
                <img src={option.image} alt='' />{option.text}
              </li>
            ))}
          </ul>
          : null}
        </div>
        <div className="generate-gallery-bar-main-sort-filter" onClick={() => {toggleFilter(1)}}>
          {sortFilterToggle ?
          <ul className="generate-gallery-bar-main-view-filter-list">
            {sortOptions.map((option, i) => (
              <li key={i} className="generate-gallery-bar-main-view-filter-list-item" onClick={() => option.onclick()}>
                <img src={option.image} alt='' />{option.text}
              </li>
            ))}
          </ul>
          : null}
        </div>
        <div className="generate-gallery-bar-main-date-filter" onClick={() => {toggleFilter(2)}}>
          {dateFilterToggle ?
          <ul className="generate-gallery-bar-main-view-filter-list-right">
            {dateOptions.map((option, i) => (
              <li key={i} className="generate-gallery-bar-main-view-filter-list-item" onClick={() => option.onclick()}>
                <img src={option.image} alt='' />{option.text}
              </li>
            ))}
          </ul>
          : null}
        </div>
      </div>
      <div className="generate-gallery-bar-progress" style={{ height: progressEnabled ? 'auto' : '0' }}>
        <div className="generate-gallery-bar-progress-fill" style={{ width: `${Math.min(Math.max(progress*100, 0), 100)}%`}} />
      </div>
    </div>
  )
}

/* images: array of image uris */
const GalleryCarousel = ({images, loadMore, onImageClick, onClick, toggle}) => {
  return (
    <div>
      <div className="generate-gallery-carousel-tab" onClick={() => onClick()}>
        <img className="generate-gallery-carousel-triangle" src={toggle ? triangle_down : triangle_up} alt='' />
      </div>
      { true ?
      <div className={cx(
        "generate-gallery-carousel",
        toggle ? "generate-gallery-carousel-open" : "generate-gallery-carousel-closed"
      )}>
        <div className="generate-gallery-carousel-images">
          { images && images.map((image, i) => (
            <img key={i} className="generate-gallery-carousel-image common-hoverable" src={image.src} onClick={() => onImageClick(image.id)} alt='' />
          ))}
        </div>
      </div>
      : null }
    </div>
  )
}

const OptionElement = ({title, placeholder, options, onChange}) => {
  return (
    <div className="generate-options-element">
      <div className="generate-options-element-title">{title}</div>
      <div className="generate-options-element-select">
        <select 
          onChange={onChange}
          defaultValue={placeholder}
        >
          <option value={placeholder} disabled>{placeholder}</option>
          {options.map((option, i) => (
            <option key={i} value={option.value}>{option.name}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

/* images: array of image uris */
const ThreeView = ({images, index = -1, loadMore}) => {
  const [currentImage, setCurrentImage] = useState(Math.max(0, index));

  useEffect(() => {
    setCurrentImage(Math.max(0, index));

    // if we're at 80%, load more images
    if (images && index > images.length * 0.8) {
      loadMore();
    }
  }, [index, images, loadMore])

  const getPrevImageIndex = () => {
    if (currentImage > 0) {
      return currentImage - 1;
    } else {
      return null
    }
  }

  const getNextImageIndex = () => {
    if (currentImage < images.length - 1) {
      return currentImage + 1;
    } else {
      return null
    }
  }

  const nextImage = () => {
    // if we're at 80%, load more images
    if (currentImage > images.length * 0.8) {
      loadMore();
    }

    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1);
    } else {
      setCurrentImage(0);
    }
  }

  const prevImage = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    } else {
      setCurrentImage(images.length - 1);
    }
  }

  return (
    <div className="generate-gallery-threeview">
      { images &&
      [
      <img key={1} className="generate-gallery-threeview-image-1" src={images[getPrevImageIndex()] ? images[getPrevImageIndex()].src : null} alt='' />,
      getPrevImageIndex() !== null && <img key={2} className="generate-gallery-threeview-left" src={arrow_gallery_left} alt='' onClick={prevImage} />,
      <img key={3} className="generate-gallery-threeview-image-2" src={images[currentImage] ? images[currentImage].src : null} alt='' />,
      getNextImageIndex() !== null && <img key={4} className="generate-gallery-threeview-right" src={arrow_gallery_right} alt='' onClick={nextImage} />,
      <img key={5} className="generate-gallery-threeview-image-3" src={images[getNextImageIndex()] ? images[getNextImageIndex()].src : null} alt='' />
      ]
      }
    </div>
  )
}

const GalleryView = ({images, index = -1, loadMore, onClick}) => {

  // scroll percentage
  const { ref, percentage } = useScrollPercentage(
    {
      windowScroll: false
    }
  );

  // debounce scroll percentage
  const windowScrolled = useCallback(debounce((percentage) => {
    if(percentage && percentage.vertical > 80) {
      if(loadMore) {
        loadMore();
      }
    }
  }, 500), [])

  useEffect(() => {
    windowScrolled(percentage);
  }, [percentage]);

  // if we're at 80% of carousel, load more images
  useEffect(() => {
    // if we're at 80%, load more images
    if (images && index > images.length * 0.8) {
      loadMore();
    }
  }, [index, images, loadMore])

  const renderImages = () => {
    let img = [];
    for (let i = 0; i < images.length; i++) {
      img.push(
      <div key={i} className="generate-gallery-galleryview-item">
        <img className="generate-gallery-galleryview-image" src={images[i].src} alt='' />
        <span className="generate-gallery-galleryview-image-ellipse">{i}</span>
        <span className="generate-gallery-galleryview-image-heart heart-filled" onClick={() => onClick(images[i].id)} />
      </div>
      );
    }
    return img;
  }

  return (
    <div ref={ref} className="generate-gallery-galleryview">
      {renderImages()}
    </div>
  );
}

// Phone view - Tinder
const TinderView = ({images, index = -1, loadMore}) => {
  const maxImages = 5;

  const [selectedImages, setSelectedImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);

  const [lastIndex, setLastIndex] = useState(index);

  // load more images if we're at 80% of the current images for carousel
  useEffect(() => {
    // if we're at 80%, load more images
    if (images && index > images.length * 0.8) {
      loadMore();
    }
  }, [index, images, loadMore])

  // if a new image is selected, add it to the selected images if its not already there
  useEffect(() => {
    if (index >= 0 && lastIndex !== index && images && images.length > index && !selectedImages.includes(images[index])) {
      // add the image to selected images, drop the oldest image if we're at maxImages
      setSelectedImages((selectedImages) => {
        if (selectedImages.length >= maxImages) {
          return [...selectedImages.slice(1), images[index]]
        } else {
          return [...selectedImages, images[index]]
        }
      })
      setLastIndex(index)
    }
  }, [images, index, selectedImages, lastIndex])


  // on load, or when images is updated and we need more selected images,
  //  grab the first maxImages images
  useEffect(() => {
    if (images && images.length > 0 && selectedImages.length < maxImages) {
      // grab the first maxImages images
      setSelectedImages(images.slice(0, maxImages))
    }
  }, [images, selectedImages])

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % Math.min(maxImages, selectedImages.length))
  }

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + maxImages) % Math.min(maxImages, selectedImages.length))
  }

  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      height: '100%',
      width: '100%',
      aspectRatio: '9/16',
      padding: '3% 5%',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        gap: '10px',
        height: '100%',
        width: '100%',
        borderRadius: '10px',
        overflow: 'hidden',
      }}>
        <img src={iphone_header} alt='' />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 22px',
        }}>
          <img style={{
            flex: '0 1 13%',
            minWidth: '0',
          }}
          src={tinder_header_left} alt='' />
          <img style={{
            flex: '0 1 30%',
            minWidth: '0',
          }}
          src={tinder_header_middle} alt='' />
          <img style={{
            flex: '0 1 10%',
            minWidth: '0',
          }}
          src={tinder_header_right} alt='' />
        </div>
        <div style={{
          display: 'grid',
          width: '100%',
          height: '100%',
          zIndex: '1',
        }}
        className="common-hoverable"
        onClick={() => {nextImage()}}
        >
          <div style={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'end',
            overflow: 'hidden',
          }}>
            <img style={{
              position: 'absolute',
              objectFit: 'cover',
              width: '100%',
              maxHeight: '100%',
              padding: '0 3px',
              borderRadius: '10px',
            }}
            src={selectedImages.length > 0 && selectedImages[currentImage] ? selectedImages[currentImage].src : null} alt='' />
            <div style={{
              position: 'absolute',
              width: 'calc(100% - 6px)',
              height: '100%',
              borderRadius: '10px',
              background: 'linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))',
            }} />
            <div style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: 'auto',
                padding: '5px',
                gap: '2px',
                alignSelf: 'start',
              }}
            >
            {new Array(maxImages).fill(0).map((_, i) => (
              <div key={i} style={{
                width: '18%',
                height: '3.02px',
                margin: 'auto',
                background: i === currentImage ? '#FFFFFF' : 'rgba(100, 100, 100, 0.4)',
                border: i === currentImage ? '1.00769px solid rgba(0, 0, 0, 0.4)' : 'none',
                borderRadius: '1.51154px',
                alignSelf: 'start',
              }} />
            ))}
            </div>
            <img style={{
              position: 'absolute',
              width: '100%',
              padding: '0 8px 8px 8px',
            }}
            src={tinder_buttons} alt='' />
          </div>
        </div>
        <div style={{
          display: 'flex',
          width: '100%',
          padding: '0',
        }}>
          <img style={{
            width: '100%',
          }}
          src={tinder_footer} alt='' />
        </div>
      </div>
      <img style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: '0',
        margin: '0',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
      src={iphone_outline} alt='' />
    </div>
  )
}

// Phone view - Coffee Meets Bagel
const CMBView = ({images, index = -1, loadMore}) => {
  const maxImages = 5;

  const [selectedImages, setSelectedImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [lastIndex, setLastIndex] = useState(index);

  const [showButtons, setShowButtons] = useState(false);

  // load more images if we're at 80% of the current images for carousel
  useEffect(() => {
    // if we're at 80%, load more images
    if (images && index > images.length * 0.8) {
      loadMore();
    }
  }, [index, images, loadMore])

  // if a new image is selected, add it to the selected images if its not already there
  useEffect(() => {
    if (index >= 0 && lastIndex !== index && images && images.length > index && !selectedImages.includes(images[index])) {
      // add the image to selected images, drop the oldest image if we're at maxImages
      setSelectedImages((selectedImages) => {
        if (selectedImages.length >= maxImages) {
          return [...selectedImages.slice(1), images[index]]
        } else {
          return [...selectedImages, images[index]]
        }
      })
      setLastIndex(index)
    }
  }, [images, index, selectedImages, lastIndex])


  // on load, or when images is updated and we need more selected images,
  //  grab the first maxImages images
  useEffect(() => {
    if (images && images.length > 0 && selectedImages.length < maxImages) {
      // grab the first maxImages images
      setSelectedImages(images.slice(0, maxImages))
    }
  }, [images, selectedImages])

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % Math.min(maxImages, selectedImages.length))
  }

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + maxImages) % Math.min(maxImages, selectedImages.length))
  }

  // scroll percentage
  const { ref, percentage } = useScrollPercentage(
    {
      windowScroll: false
    }
  );

  // debounce scroll percentage
  const windowScrolled = useCallback((percentage) => {
    if(percentage && percentage.vertical > 0.7) {
      setShowButtons(true)
    } else {
      setShowButtons(false)
    }
  }, []);

  useEffect(() => {
    windowScrolled(percentage);
  }, [percentage]);

  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      aspectRatio: '9/16',
      padding: '3% 5%',
    }}>
      <div style={{ 
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: '100%',
        backgroundColor: 'white', 
      }}>
        <img src={iphone_header} alt='' />
        <div style={{
          display: 'flex',
          height: 'auto',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white', 
          padding: '10px 22px 20px 10px',
          marginBottom: '10px',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.13)',
          zIndex: 2,
        }}>
          <img style={{
            width: '6vh',
            height: '6vh',
            borderRadius: '50%',
            objectFit: 'cover',
            borderWidth: '3px',
            borderStyle: 'solid',
            padding: '2px',
            borderColor: 'rgba(114, 125, 190, 1)',
          }}
          src={selectedImages.length > 0 && selectedImages[0] ? selectedImages[0].src : null} alt='' />
          <div style={{
            flex: '0 1 auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
            minWidth: '0',
            color: 'rgba(114, 125, 190, 1)',
            fontSize: '2vh',
            fontWeight: '500',
            textAlign: 'center',
          }}>
            720
            <img style={{
              height: '2vh',
            }}
            src={cmb_bean} alt='' />
          </div>
        </div>
      </div>
      <div ref={ref} style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        margin: '-20px 0 0 0',
        padding: '30px 0 0 0',
        gap: '10px',
        height: 'auto',
        width: '100%',
        borderRadius: '10px',
        overflowY: 'scroll',
      }}>
        <div style={{
          display: 'grid',
          width: '100%',
          height: '100%',
          zIndex: '1',
        }}
        className="common-hoverable"
        onClick={() => {nextImage()}}
        >
          <div style={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'end',
            height: '250px',
            overflow: 'hidden',
          }}>
            <img style={{
              position: 'absolute',
              objectFit: 'cover',
              objectPosition: 'center 20%',
              width: '100%',
              height: '100%',
              padding: '0 8px',
              borderRadius: '30px',
            }}
            src={selectedImages.length > 0 && selectedImages[currentImage] ? selectedImages[currentImage].src : null} alt='' />
            <div style={{
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: '100%',
              borderRadius: '30px',
              background: 'linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))',
              overflow: 'visible',
            }} />
              <div style={{
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                left: '10%',
                bottom: '5%',
                margin: 'auto',
                padding: '5px 14px',
                borderRadius: '30%',
                background: 'rgba(10, 10, 10, 0.4)',
                alignSelf: 'start',
                justifySelf: 'start',
              }}>
                {currentImage+1}/{selectedImages.length}
              </div>
          </div>
        </div>
        <div style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
          padding: '0 0px 100px 10px',
          zIndex: '1',
        }}>
          <span style={{color: 'black', fontWeight: '600', fontSize: '4vh'}}>Justin</span>
          <span style={{color: 'black', fontWeight: '500', fontSize: '3vh'}}>31, Singapore</span>
          <span style={{color: 'rgba(114, 125, 190, 1)', fontWeight: '500', fontSize: '1.7vh'}}>
            <img style={{
              objectFit: 'cover',
              height: '15px',
              padding: '0 10px 0 0',
            }}
            src={cmb_briefcase} alt='' />
            Actor, Model, Television Host</span>
          <span style={{color: 'gray', fontWeight: '500', marginTop: '10px', fontSize: '1.5vh', lineHeight: '2vh'}}>I am...</span>
          <span style={{color: 'gray', fontWeight: '500', fontSpacing: '0.5px', fontSize: '1.5vh', lineHeight: '2vh', paddingRight: '50px'
          }}>In pursuing my own self and in search of wonder, someone who strives to be always expanding my horizons.</span>
        </div>
      </div>
      <div style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        gap: '0px',
        justifyContent: 'middle',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        padding: '0',
        left: 0,
        right: 0,
        bottom: 0,
      }}>
        <div style={{
          visibility: showButtons ? 'visible' : 'hidden',
          display: 'flex',
          flexDirection: 'row',
          gap: '0px',
          justifyContent: 'middle',
          alignItems: 'center',
          width: '100%',
          height: 'auto',
          padding: '0 12px 12px 12px',
          left: 0,
          right: 0,
          bottom: 0,
        }}>
          <img style={{flex: '1 1 20%', minWidth: '10%', maxHeight: '60px'}}
          src={cmb_x} alt='' />
          <img style={{flex: '1 1 20%', minWidth: '10%', maxHeight: '80px'}}
          src={cmb_heart} alt='' />
          <img style={{flex: '1 1 20%', minWidth: '10%', maxHeight: '60px'}}
          src={cmb_chat} alt='' />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '30px',
          justifyContent: 'space-between',
          alignItems: 'end',
          background: 'white',
          width: '90%',
          height: 'auto',
          padding: '0 20px 20px 20px',
          margin: 'auto',
          left: 0,
          right: 0,
          bottom: 0,
          borderBottomRightRadius: '50%',
          borderBottomLeftRadius: '50%',
          overflow: 'hidden',
          zIndex: '2',
        }}>
          <img style={{flex: '1 1 20%', minWidth: '10%', maxHeight: '50px'}}
          src={cmb_suggested} alt='' />
          <img style={{flex: '1 1 20%', minWidth: '10%', maxHeight: '50px'}}
          src={cmb_discover} alt='' />
          <img style={{flex: '1 1 20%', minWidth: '10%', maxHeight: '50px'}}
          src={cmb_chats} alt='' />
        </div>
      </div>
      <img style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: '0',
        margin: '0',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: '3',
        pointerEvents:'none',
      }}
      src={iphone_outline} alt='' />
    </div>
  )
}

const PhoneView = ({images, index = -1, loadMore, type='tinder'}) => {

  const getType = (type) => {
    if (type === 'tinder') {
      return <TinderView images={images} index={index} loadMore={loadMore} />
    } else if (type === 'cmb') {
      return <CMBView images={images} index={index} loadMore={loadMore} />
    } else {
      return <TinderView images={images} index={index} loadMore={loadMore} />
    }
  }

  return (
    <div className="generate-gallery-phoneview">
      {getType(type)}
    </div>
  )
}

const Generate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeJobs, setActiveJobs] = useState([]); // jobs waiting to be finished

  const [optionsSidebarState, setOptionsSidebarState] = useState(null); // whether to show options sidebar
  const [state, ] = useState(true); // whether to show progress bar full screen
  const [view, setView] = useState(2);
  const [viewType, setViewType] = useState('tinder');
  const [carousel, setCarousel] = useState(true);

  const [progressEnabled, setProgressEnabled] = useState(false); // whether to show progress bar
  const [progress, setProgress] = useState(0.0);

  // selected options
  const [selectedQuality, setSelectedQuality] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [selectedImageCount, setSelectedImageCount] = useState(1);

  const [maxImageCount, setMaxImageCount] = useState(null);

  const [imageIndex, setImageIndex] = useState(-1);

  const toggleCarousel = () => {
    setCarousel(!carousel);
  }

  // slidebar state (for mobile)
  // get value (if it exists) from query string
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const generateToggle_ = params.get('open');
    if(generateToggle_ === 'true') {
      setOptionsSidebarState(true);
    } else if (generateToggle_ === 'false') {
      setOptionsSidebarState(false);
    } else {
      setOptionsSidebarState(null);
    }
  }, [location.search]);

  // user
  const user = useSelector(state => state.user.user);

  // uploaded images (generated)
  const generatedImages = useSelector(state => state.image.generatedImages);
  const models = useSelector(state => state.model.models);
  const styles = useSelector(state => state.style.styles);

  // placeholder images
  const [placeholderImages, setPlaceholderImages] = useState([]);

  // images to show
  const [images, setImages] = useState([]);

  // sort option
  const [sortBy, setSortBy] = useState('date_desc');

  // "date_asc" : "date_desc"
  // "liked_asc" : "liked_desc"

  // current computed credit cost
  const [creditCost, setCreditCost] = useState(null);

  // if sort changes, get entire page again
  useEffect(() => {
    setImages(placeholderImages)

    dispatch(actions.imageActions.loadFirstPageGeneratedFunc(sortBy === 'date_asc' ? true : false))
  }, [placeholderImages, sortBy, dispatch])

  // if placeholder images, generated images, or sort changes, update visible images
  useEffect(() => {
    // compute images to show
    if(generatedImages && generatedImages.length > 0) {
      const sortedImages = generatedImages.sort((a, b) => {
        if (sortBy === 'date_asc') {
          return new Date(a.createdAt) - new Date(b.createdAt);
        } else if (sortBy === 'date_desc') {
          return new Date(b.createdAt) - new Date(a.createdAt);
        } else if (sortBy === 'liked_asc') {
          return a.liked - b.liked;
        } else if (sortBy === 'liked_desc') {
          return b.liked - a.liked;
        } else {
          return 0;
        }
      });

      setImages([...placeholderImages, ...sortedImages]);
    } else {
      setImages(placeholderImages);
    }
  }, [generatedImages, placeholderImages, sortBy])

  // always fetch styles again
  useEffect(() => {
    // only fetch a new page if we don't have any images yet (we don't want to overwrite existing images)
    if(!generatedImages || generatedImages.length === 0) dispatch(actions.imageActions.loadFirstPageGeneratedFunc(sortBy === 'date_asc' ? true : false));

    dispatch(actions.styleActions.loadStyles);
  }, [dispatch, generatedImages, sortBy])

  // load more images (and debounce)
  const loadMore = useCallback(debounce(() => {
    dispatch(actions.imageActions.loadNextPageGeneratedFunc(sortBy === 'date_asc' ? true : false));
  }, 500), [sortBy, dispatch]);

  // when selected style gets updated
  useEffect(() => {
    if (styles && selectedStyle) {
      setMaxImageCount(styles[selectedStyle].maxImages);
    }
  }, [styles, selectedStyle])

  // updated credit cost when relevant parameters change
  useEffect(() => {
    if (selectedImageCount && selectedStyle && styles) {
      setCreditCost(styles[selectedStyle].credits * selectedImageCount);
    }
  }, [selectedImageCount, selectedStyle, styles])

  // queue generation of images
  const generate = async () => {
    if(selectedStyle === null) {
      console.log("no style selected")
      return
    }

    let style = styles[selectedStyle]
    let model = models[0]._id
    let output = Math.random().toString(36).substring(2, 15)

    // if imageset provided, use that
    let imageset_images = []
    if (style.imageSets && style.imageSets.length > 0) {
      // get all images from all imagesets
      for(let i = 0; i < style.imageSets.length; i++) {
        imageset_images.push(...(style.imageSets[i].images))
      }

      // pick selectedImageCount random images from imageset
      imageset_images = imageset_images.sort(() => Math.random() - Math.random()).slice(0, selectedImageCount)

      // fetch image data from server
      imageset_images = await Promise.all(imageset_images.map(async (img) => {
        return (await ImageService.getImage(img)).data.s3_key
      }))
    }

    // data
    let body = {
      base_model: model,
      style: style._id,
      options: {
        images: imageset_images, // for control, not used in normal mode
        output: output, // output name base
        num_samples: selectedImageCount, // total number of images to generate (will be internally set to 1 if control...)
      }
    }
    
    // start the job
    const response = await JobService.startInference(
      body
    )

    // add job to active jobs
    setActiveJobs([...activeJobs, { _id: response.data._id, numImages: selectedImageCount, started: (new Date()).getTime() }])

    // set index to 0
    setImageIndex(0)

    // show progress bar if not already shown
    if(!progressEnabled) {
      setProgress(0)
      setProgressEnabled(true)
    }

    // refresh the user to get their new tokens
    const user = await AuthService.update()
    dispatch(actions.userActions.login(user))
  }

  // set active jobs on mount
  useEffect(() => {
    const fetchActiveJobs = async () => {
      const response = (await JobService.getAllInferenceJobs()).data
                                       .filter((j) => j.state === "running" || j.state === "pending" || j.state === "processing" || j.state === "starting")
                                       
      setActiveJobs(response.map((j) => { return { _id: j._id, numImages: j.options.num_samples, started: (new Date(j.createdAt)).getTime() } } ));
    }
    fetchActiveJobs();
  }, [])

  // whenever active jobs change, update placeholders
  useEffect(() => {
    // placeholder images
    let placeholder = [];
    activeJobs.forEach((job, i) => {
      for (let j = 0; j < job.numImages; j++) {
        placeholder.push({id: 'placeholder', src: generating_placeholder, key: 'placeholder'});
      }
    })
    setPlaceholderImages(placeholder);
  }, [activeJobs])

  // check active jobs on a timer
  useEffect(() => {
    const interval = setInterval(async () => {
      // check all active jobs
      let job_percents = []
      
      for(let i=0; i < activeJobs.length; i++) {
        let job = activeJobs[i]
        let job_percent = 0.0

        // get job status
        const response = await JobService.getInferenceStatus(job._id)
        if(response.data.state === "succeeded") { 
          // update images
          dispatch(actions.imageActions.loadFirstPageGeneratedFunc(sortBy === 'date_asc' ? true : false));

          // remove job from active jobs
          setActiveJobs(activeJobs.filter((j) => j._id !== job._id))

          job_percent = 1.0
        } else if(response.data.state === "failed" || response.data.state === "cancelled" || response.data.state === "error") {
          // remove job from active jobs
          setActiveJobs(activeJobs.filter((j) => j._id !== job._id))

          job_percent = 1.0
        } else {
          job_percent = job.started ? ((new Date()).getTime() - job.started)/(1000 * 60 * 5) : 0.0
        }
        job_percents.push(job_percent)
      }

      // update progress
      const new_percent = job_percents.reduce((a, b) => a + b, 0.0) / parseFloat(job_percents.length)
      setProgress(new_percent)
      setProgressEnabled(job_percents.length > 0)

      // if no active jobs, make sure progress is not shown
      if(job_percents.length === 0) {
        setProgressEnabled(false)
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [activeJobs])

  const changeView = (i, type='tinder') => {
    setView(i)
    setViewType(type)
  }

  const renderView = (type, viewType) => {
    // if there are no images, show empty view
    if(!images || images.length === 0) {
      return [] // expects array of components, important!
    }

    const setImageIndexById = (id) => {
      setImageIndex(images.findIndex((img) => img.id === id))
    }

    switch (type) {
      case 0:
        return [
        <ThreeView images={images} index={imageIndex} loadMore={loadMore} />, 
        <GalleryCarousel images={images} toggle={carousel} onImageClick={(id) => { setImageIndexById(id) }} onClick={() => toggleCarousel()} loadMore={loadMore}/>
      ]
      case 1:
        return [<GalleryView images={images} index={imageIndex} onClick={(i) => alert(i)} loadMore={loadMore} />]
      case 2:
        return [
        <PhoneView images={images} index={imageIndex} onClick={(i) => alert(i)} loadMore={loadMore} type={viewType} />,
        <GalleryCarousel images={images} toggle={carousel} onImageClick={(id) => { setImageIndexById(id) }} onClick={() => toggleCarousel()} loadMore={loadMore} />
      ]
      default:
        return [
        <ThreeView images={images} index={imageIndex} loadMore={loadMore} />, 
        <GalleryCarousel images={images} toggle={carousel} onImageClick={(id) => { setImageIndexById(id) }} onClick={() => toggleCarousel()} loadMore={loadMore} />
      ]
    }
  }

  const sortImagesByDate = (asc_desc) => {
    setSortBy(asc_desc ? "date_asc" : "date_desc")
  }

  const sortImagesByLiked = (asc_desc) => {
    setSortBy(asc_desc ? "liked_asc" : "liked_desc")
  }

  return (
    <div className="generate">
      <div className={cx(
          "generate-options",
          optionsSidebarState===true ? "generate-options-open" : optionsSidebarState===false ? "generate-options-closed" : null
        )}
         >
        <div className="generate-options-top">
          <a className="generate-options-top-left"  style={{ display: optionsSidebarState !== false ? 'block' : 'none' }} href="./upload"><img src={arrow_left_2} alt='' /><span className="generate-options-top-text">Back to Upload</span></a>
          <div className="generate-options-top-right" 
            onClick={ ()=> { 
              setOptionsSidebarState(
                optionsSidebarState === null ? 
                  false : 
                  !optionsSidebarState
              )
              if(optionsSidebarState === true) {
                navigate({
                  pathname: '/generate',
                  search: '?open=false'
                })
              } else if(optionsSidebarState === false) {
                navigate({
                  pathname: '/generate',
                  search: '?open=true'
                })
              } else {
                navigate({
                  pathname: '/generate',
                  search: '?open=false'
                })
              }
          }}>
            <img src={optionsSidebarState !== false ? arrow_left_1 : arrow_right_1} alt='' /></div>
        </div>
        {(models && models.length > 0) &&
        [
        <div className="generate-options-header" style={{ display: optionsSidebarState !== false ? 'block' : 'none' }}>
          Image
          Generation
          Options
        </div>,
        <div className="generate-options-elements" style={{ display: optionsSidebarState !== false ? 'block' : 'none' }}>
        <OptionElement title={"Resolution:"} placeholder={"Select resolution..."} options={['Standard'].map((s) => {return {name: s, value: s}})} />
        <OptionElement title={"Style:"} placeholder={"Select style..."} options={styles ? styles.map((s, i) => {return {name: s.name, value: i}}) : ''} onChange={(e) => {
          setSelectedStyle(e.target.value)
          setSelectedImageCount(1)
          }} />
        <OptionElement title={"Image count:"} placeholder={"Select image count..."} options={maxImageCount ? Array.from({length: maxImageCount}, (v,i) => {return {name: i+1, value: i+1}}) : []} onChange={(e) => setSelectedImageCount(e.target.value)} />
        </div>,
        <div className="generate-options-bottom" style={{ display: optionsSidebarState !== false ? 'block' : 'none' }}>
          <div className="generate-options-bottom-title">
            <div className="generate-options-bottom-title-1">Credit Cost:</div>
            <span className="generate-options-bottom-title-2">{ creditCost === 1 ? '1 credit' : `${(creditCost ? creditCost : '?' )} credits`}</span>
          </div>
          <div className="generate-options-bottom-card">
            <img className="generate-options-bottom-card-image" src={coin} alt='' />
            <div className="generate-options-bottom-card-text">
              <div className="generate-options-bottom-card-text-title">Available Credits</div>
              <div className="generate-options-bottom-card-text-subtitle">{ user ? user.user.membership.credits : 0 }</div>
            </div>
            <button 
              className="generate-options-bottom-card-buy" 
              onClick = {() => { navigate('/membership') }}
            >Buy more</button>
          </div>
          <Button 
            text={ 
              !user || creditCost === null ? "..." :
              creditCost <= user.user.membership.credits ? "Generate" :
              "Not Enough Credits"
            } 
            className={"generate-options-bottom-button"} 
            highlight={
              !user || creditCost === null ? false :
              creditCost <= user.user.membership.credits ? true :
              false
            } 
            onClick={() => {
              if(user && creditCost !== null && creditCost <= user.user.membership.credits) {
                generate()
              } else {
                navigate('/membership')
              }
            }} />
        </div>].map((item, i) => (
          React.cloneElement(item, { key: i })
        ))
        }
        {(!models || models.length === 0) &&
        <div className="generate-options-bottom" style={{ display: optionsSidebarState !== false ? 'block' : 'none' }}>
          <Button text={"Go To Process Model First"} className={"generate-options-bottom-button"} highlight={false} onClick={() => {navigate('/upload')}} />
        </div>}
      </div>
      {state ? 
      <div className="generate-view">
        <GalleryBar 
          progressEnabled={progressEnabled} 
          progress={progress} 
          viewOptions={
            [
              {
                image: one_image_view,
                text: 'One-Image View',
                onclick: () => changeView(0)
              },
              {
                image: gallery_view,
                text: 'Gallery View',
                onclick: () => changeView(1)
              },
              {
                image: phone_view,
                text: 'Tinder View',
                onclick: () => changeView(2, 'tinder')
              },
              {
                image: phone_view,
                text: 'CMB View',
                onclick: () => changeView(2, 'cmb')
              }
            ]
          } 
          sortOptions={
            [
              {
                image: null,
                text: 'TBD',
                onclick: () => {}
              },
            ]
          } 
          dateOptions={
            [
              {
                image: sort_asc,
                text: 'Date created (newest)',
                onclick: () => sortImagesByDate(false)
              },
              {
                image: sort_desc,
                text: 'Date created (oldest)',
                onclick: () => sortImagesByDate(true)
              },
              {
                image: sort_asc,
                text: 'Liked (newest)',
                onclick: () => sortImagesByLiked(false)
              },
              {
                image: sort_desc,
                text: 'Liked (oldest)',
                onclick: () => sortImagesByLiked(true)
              },
            ]
          } 
          />
        {renderView(view, viewType)
          .map((item, i) => (
            React.cloneElement(item, { key: i })
        ))}
      </div>
      : 
      <div className="generate-gallery">
        <div className="generate-gallery-item-text">
          <h3 className="generate-gallery-item-heading">Your photos are being generated...</h3>
          <p className="generate-gallery-item-paragraph">It may take 2 - 4 minutes. You can access previously generated photos by going to the Home page.</p>
          <button className="generate-gallery-item-button">Visit home page</button>
        </div>
      </div>
      }
    </div>
  );
};

export default Generate;