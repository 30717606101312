import axios from "axios";
import config from "../config/config"

const API_URL = `${config.api}/jobs/`;

const startInference = (body) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
  
  return axios.post(
    `${API_URL}/infer/`,
    body,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const getInferenceStatus = (id) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
  
  return axios.get(
    `${API_URL}/infer/${id}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const getAllInferenceJobs = () => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token

  return axios.get(
    `${API_URL}/infer/`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const startTraining = (body) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
  
  return axios.post(
    `${API_URL}/train/`,
    body,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const getTrainingStatus = (id) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
  
  return axios.get(
    `${API_URL}/train/${id}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const getAllTrainingJobs = () => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token

  return axios.get(
    `${API_URL}/train/`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const JobService = {
  startInference,
  getInferenceStatus,
  getAllInferenceJobs,
  startTraining,
  getTrainingStatus,
  getAllTrainingJobs
};

export default JobService; 