export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER_IMAGE = "SET_USER_IMAGE";

export const login = (user) => {
    return {
        type: LOGIN,
        user: user
    }
}

export const logout = () => {
    return {
        type: LOGOUT,
        user: null
    }
}

export const setUserImage = (image) => {
    return {
        type: SET_USER_IMAGE,
        image: image
    }
}

const actions = {
    login,
    logout,
    setUserImage
}

export default actions