import React, { useState } from 'react';
import useScrollPercentage from 'react-scroll-percentage-hook';
import { useNavigate } from 'react-router-dom';

import cx from 'classnames';
import config from '../../config/config';
import { BlurryEllipse } from '../../common/common';

import { MembershipCards } from '../Account/Membership';

import './Landing.css';

import icon_pencil from '../../assets/icons_landing/pencil.svg';
import icon_heart from '../../assets/icons_landing/heart.png';
import icon_heart_contained from '../../assets/icons_landing/heart-contained.png';
import icon_hinge from '../../assets/icons_landing/hinge.png';
import icon_tinder from '../../assets/icons_landing/tinder.png';

import icon_hiw_1 from '../../assets/icons_landing/hiw-1.svg';
import icon_hiw_2 from '../../assets/icons_landing/hiw-2.svg';
import icon_hiw_3 from '../../assets/icons_landing/hiw-3.svg';
import icon_hiw_4 from '../../assets/icons_landing/hiw-4.svg';
import icon_hiw_photo from '../../assets/icons_landing/hiw-photo.png';
import icon_hiw_match from '../../assets/icons_landing/hiw-match.svg';

import photo_feature_1 from '../../assets/icons_landing/photo-1.png';
import photo_feature_2 from '../../assets/icons_landing/photo-2.png';
import photo_feature_3 from '../../assets/icons_landing/photo-3.png';

import icon_pencil_2 from '../../assets/icons_landing/pencil-2.svg';

import screen_1 from '../../assets/icons_landing/screen-1.png';
import screen_2 from '../../assets/icons_landing/screen-2.png';
import screen_3 from '../../assets/icons_landing/screen-3.png';
import icon_yellow_star from '../../assets/icons_landing/yellow-star.svg';
import icon_purple_heart from '../../assets/icons_landing/purple-heart.svg'

import icon_chat from '../../assets/icons_landing/text-messages.svg';

import profile_1 from '../../assets/icons_landing/profile-1.png';
import profile_2 from '../../assets/icons_landing/profile-2.png';
import profile_3 from '../../assets/icons_landing/profile-3.png';
import profile_4 from '../../assets/icons_landing/profile-4.png';
import icon_stars from '../../assets/icons_landing/stars.svg';

import icon_facebook from '../../assets/icons_landing/facebook.svg';
import icon_instagram from '../../assets/icons_landing/instagram.svg';

import icon_left_arrow_empty from '../../assets/icons_landing/left-arrow-empty.svg';
import icon_right_arrow_empty from '../../assets/icons_landing/right-arrow-empty.svg';

import icon_hamburger from '../../assets/icons_landing/hamburger.svg';
import icon_exit from '../../assets/icons_landing/exit.svg';

/* general card container */
const Card = ({ id, className, children }) => {
  return (
    <div id={id} className={cx("landing-card", className)}>
      {children}
    </div>
  );
}

/* Logo: rizzer.ai */
const Logo = () => {
  return (
    <span className="landing-nav-title"><a href="./">rizzer<span className="landing-nav-title-dot">.</span>ai</a></span>
  );
}

/* Navigation Links */
const NavLinks = ({ links }) => {
  return (
    <div className="landing-nav-links">
      {links.map((link, i) => (
      <a key={i}
        className="landing-nav-link"
        href={link.target}
      >
      {link.text}
      </a>
      ))}
    </div>
  );
}

/* Login/Logout */
const NavRight = ({ userIcon, isAdmin, userCredits, modalSettings }) => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/login');
    window.location.reload();
  }

  return (
    <button className="landing-nav-button button-no-style" onClick={() => { goToLogin() }}>
      Log In
    </button>
  );
}

/* HIW items */
const HIWItem = ({ image, heading, subheading, x, y }) => {
  const grid_position = {
    gridColumnStart: y,
    gridRowStart: x
  }

  return (
    <div className="landing-hiw-content-left-item" style={grid_position}>
      <img className="landing-hiw-content-left-item-image" src={image} alt='' />
      <span className="landing-hiw-content-left-item-heading">{heading}</span>
      <span className="landing-hiw-content-left-item-subheading">{subheading}</span>
    </div>
  );
}

/* Features */
const Features = () => {
  return (
    <div className="landing-features">
      <div className="landing-features-heading">
        rizzer
        <span className="common-gradient-text-1">.</span>
        ai - 
        <span className="common-gradient-text-2"> simple </span>
        and
        <span className="common-gradient-text-2"> efficient </span>
        tool<br/>to generate your best photos
      </div>
      <div className="landing-features-content">
        <div className="landing-features-content-row">
          <Card className="landing-features-content-row-item landing-features-content-row-item-large landing-features-content-row-item-1">
            <span className="landing-features-content-row-item-number">01</span>
            <span className="landing-features-content-row-item-heading">Personalized images</span>
            <span className="landing-features-content-row-item-subheading">
              AI technology analyzes
              <span style={{ color: 'var(--color-primary)'}}> your facial features and create a photo that highlights your best qualities</span>
              . Choose from a variety of styles, from classic to quirky, to find the perfect photo that suits your personality.
            </span>
          </Card>
          <Card className="hide-on-mobile landing-features-content-row-item landing-features-content-row-item-small landing-features-content-row-item-2">
            <BlurryEllipse 
              position='absolute'
              width={'600px'}
              height={'600px'}
              left={'-400px'}
              top={'-400px'}
              rotate={27.66}
              start_color = 'rgba(255,66,128, 0.5)'
              middle_color = 'rgba(255,66,128, 0.25)'
              end_color = 'rgba(0, 0, 0, 0)'
              z = {'auto'}
            />
            <BlurryEllipse 
              position='absolute'
              width={'600px'}
              height={'600px'}
              left={'55%'}
              top={'0%'}
              rotate={27.66}
              start_color = 'rgba(219, 0, 255, 0.5)'
              middle_color = 'rgba(219, 0, 255, 0.25)'
              end_color = 'rgba(0, 0, 0, 0)'
              z = {'auto'}
            />
            <img style={{ position: 'absolute', width: '120px', top: '80px', left: '40px' }} src={photo_feature_2} alt='' />
            <img style={{ position: 'absolute', width: '120px', top: '93px', left: '170px' }} src={photo_feature_1} alt='' />
            <img style={{ position: 'absolute', width: 'calc(100% - 120px - 120px - 200px)', height: '60px', top: '60px', left: '220px' }} src={icon_pencil_2} alt='' />
            <img style={{ position: 'relative', height: '100%', right: '0px'}} src={photo_feature_3} alt='' />
          </Card>
          <Card className="hide-on-desktop landing-features-content-row-item landing-features-content-row-item-small landing-features-content-row-item-2" />
        </div>
        <div className="landing-features-content-row">
          <Card className="hide-on-mobile landing-features-content-row-item landing-features-content-row-item-small landing-features-content-row-item-3">
            <BlurryEllipse 
              position='absolute'
              width={'600px'}
              height={'600px'}
              left={'-400px'}
              top={'0%'}
              rotate={27.66}
              start_color = 'rgba(219, 0, 255, 0.5)'
              middle_color = 'rgba(219, 0, 255, 0.25)'
              end_color = 'rgba(0, 0, 0, 0)'
              z = {'auto'}
            />
            <BlurryEllipse 
              position='absolute'
              width={'600px'}
              height={'600px'}
              left={'55%'}
              top={'-400px'}
              rotate={27.66}
              start_color = 'rgba(255,66,128, 0.5)'
              middle_color = 'rgba(255,66,128, 0.25)'
              end_color = 'rgba(0, 0, 0, 0)'
              z = {'auto'}
            />
            <img style={{ height: '80%', marginTop: '-73px', borderRadius: '10px' }} src={screen_1} alt='' />
            <img style={{ height: '80%', marginTop: '73px', borderRadius: '10px' }} src={screen_3} alt='' />
            <img style={{ height: '80%', marginTop: '-73px', borderRadius: '10px', zIndex: '1'}} src={screen_2} alt='' />
            <img style={{ position: 'absolute', top: '10%', left: '35%', borderRadius: '150px'}} src={icon_purple_heart} alt='' />
            <img style={{ position: 'absolute', top: '69%', left: '66%' }} src={icon_yellow_star} alt='' />
          </Card>
          <Card className="landing-features-content-row-item landing-features-content-row-item-large landing-features-content-row-item-4">
            <span className="landing-features-content-row-item-number">02</span>
            <span className="landing-features-content-row-item-heading">AI-enhanced dating app photos</span>
            <span className="landing-features-content-row-item-subheading">
              Our state-of-the-art AI generates images that are optimized for dating apps like
              <span style={{ color: 'var(--color-primary)'}}> Tinder, Hinge, Bumble, and Coffee Meets Bagel.</span>
            </span>
          </Card>
          <Card className="hide-on-desktop landing-features-content-row-item landing-features-content-row-item-small landing-features-content-row-item-3" />
        </div>
        <div className="landing-features-content-row">
          <Card className="landing-features-content-row-item landing-features-content-row-item-large landing-features-content-row-item-5">
            <span className="landing-features-content-row-item-number">03</span>
            <span className="landing-features-content-row-item-heading">User feedback and advice</span>
            <span className="landing-features-content-row-item-subheading">
              <span style={{ color: 'var(--color-primary)'}}>Join our community </span>
              of users to share experiences, trade advice on effective images, and show off your rizz!
            </span>
          </Card>
          <Card className="hide-on-mobile landing-features-content-row-item landing-features-content-row-item-small landing-features-content-row-item-6">
            <BlurryEllipse 
              position='absolute'
              width={'600px'}
              height={'600px'}
              left={'-400px'}
              top={'-400px'}
              rotate={27.66}
              start_color = 'rgba(255,66,128, 0.5)'
              middle_color = 'rgba(255,66,128, 0.25)'
              end_color = 'rgba(0, 0, 0, 0)'
              z = {'auto'}
            />
            <BlurryEllipse 
              position='absolute'
              width={'600px'}
              height={'600px'}
              left={'55%'}
              top={'0%'}
              rotate={27.66}
              start_color = 'rgba(219, 0, 255, 0.5)'
              middle_color = 'rgba(219, 0, 255, 0.25)'
              end_color = 'rgba(0, 0, 0, 0)'
              z = {'auto'}
            />
            <img style={{ height: '50%' }} src={icon_chat} alt='' />
          </Card>
          <Card className="hide-on-desktop landing-features-content-row-item landing-features-content-row-item-small landing-features-content-row-item-6" />
        </div>
      </div>
    </div>
  );
}

/* Pricing */
const Pricing = () => {
  const navigate = useNavigate()

  const membershipInfo = config.pricing

  return (
    <MembershipCards 
      membershipInfo={membershipInfo}
      sliderHeader="Ain't nobody calling your rizz mid no more"
      sliderText=''
      onClick={() => { navigate('/membership'); navigate(0) }}
    />
  ) 
}

const Community = () => {
  const communityPictures = [
    {
      account: '@markgilford',
      src: profile_1,
      coords: [1, 2, 1, 3],
      fit: 'cover'
    },
    {
      account: '@markgilford',
      src: profile_3,
      coords: [2, 3, 1, 2],
      fit: 'cover'
    },
    {
      account: '@markgilford',
      src: profile_4,
      coords: [2, 3, 2, 3],
      fit: 'cover'
    },
    {
      account: '@markgilford',
      src: profile_2,
      coords: [3, 4, 1, 3],
      fit: 'cover'
    }
  ]

  return (
    <div className='landing-community-container'>
      <div className='landing-community-pre-header' >We value</div>
      <div className='landing-community-header' >Community</div>
      <div className='landing-community-subheader' >
        See what others are saying about their experience with Rizzer.AI! Our users have had great success using our app to
        create high-quality dating profile photos that help them stand out from the crowd. Check out some of their testimonials
        below and discover why Rizzer.AI is the go-to choice for anyone looking to improve their online dating presence.</div>
      <div className='landing-community-post-header' >Use the hashtag #rizzer.ai to be featured!</div>
      <div className='landing-community-pictures' >
        {communityPictures.map((picture, i) => { return (
        <div key={i}
          style={{
            gridColumnStart: `${picture.coords[0]}`,
            gridColumnEnd: `${picture.coords[1]}`,
            gridRowStart: `${picture.coords[2]}`,
            gridRowEnd: `${picture.coords[3]}`
          }}
          className={cx(
            picture.coords[0] === 3 ? 'hide-on-mobile' : null,
            'landing-community-picture' 
          )}>
          <img 
            style={{ objectFit: picture.fit }}
            src={picture.src} alt='' />
          <span 
          style={{
            gridColumnStart: `${picture.coords[0]}`,
            gridColumnEnd: `${picture.coords[1]}`,
            gridRowStart: `${picture.coords[2]}`,
            gridRowEnd: `${picture.coords[3]}`
          }}
          className={cx(
            picture.coords[0] === 3 ? 'hide-on-mobile' : null,
            'landing-community-picture-account'
            )} >{picture.account}</span>
        </div>
        )})}
      </div>
    </div>
  ) 
}

const Testimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0)

  const testimonials = [
    "1. I was skeptical at first about using an AI app to generate my dating profile photo, but I'm so glad I gave Rizzer.AI a chance. The photo it created for me is amazing, and I've already gotten more matches and messages than before. Highly recommend!",
    "2. Rizzer.AI has saved me so much time and hassle when it comes to creating a new dating profile photo. Before, I would spend hours trying to get the perfect shot, but now I can just upload a photo and let the AI do the work. It's a game-changer!",
    "3. I love the variety of styles that Rizzer.AI offers. I've tried out a few different ones, and each time I'm impressed by the creativity and uniqueness of the photo it generates. It's a fun and easy way to spice up my dating profile."
  ]

  const handleTestimonialChange = (direction) => {
    if (direction === 'left') {
      if (currentTestimonial === 0) {
        setCurrentTestimonial(2)
      } else {
        setCurrentTestimonial(currentTestimonial - 1)
      }
    } else {
      if (currentTestimonial === 2) {
        setCurrentTestimonial(0)
      } else {
        setCurrentTestimonial(currentTestimonial + 1)
      }
    }
  }

  return (
    [
    <div className={cx(
      'landing-testimonials-container',
      currentTestimonial === 0 ? 
        'landing-testimonials-container-pos-1' : 
      currentTestimonial === 1 ?
        'landing-testimonials-container-pos-2' :
        'landing-testimonials-container-pos-3'
      )} >
      <BlurryEllipse 
        position='absolute'
        width={'1400px'}
        height={'1000px'}
        left={'calc(50% - 1400px/2)'}
        top={'calc(50% - 1000px/2)'}
        rotate={0}
        start_color = 'rgba(219, 0, 255, 0.5)'
        middle_color = 'rgba(219, 0, 255, 0.25)'
        end_color = 'rgba(0, 0, 0, 0)'
        z = {'0'}
      />
      <Card className='landing-testimonials-card-1' >
        <img className='landing-testimonials-stars' src={icon_stars} alt='' />
        {testimonials[0]}
      </Card>
      <Card className='landing-testimonials-card-2' >
        <img className='landing-testimonials-stars' src={icon_stars} alt='' />
        {testimonials[1]}
      </Card>
      <Card className='landing-testimonials-card-3' >
        <img className='landing-testimonials-stars' src={icon_stars} alt='' />
        {testimonials[2]}
      </Card>
    </div>,
    <div className='hide-on-desktop landing-testimonials-button-container'>
      <button 
        className='landing-testimonials-button' 
        onClick={() => { handleTestimonialChange('left') }} >
          <img src={icon_left_arrow_empty} alt='' />
      </button>
      <button 
        className='landing-testimonials-button' 
        onClick={() => { handleTestimonialChange('right') }} >
          <img src={icon_right_arrow_empty} alt='' />
      </button>
    </div>
    ].map((item, i) => (
      React.cloneElement(item, { key: i })
    ))
  ) 
}

const Trial = () => {
  const navigate = useNavigate()
  
  return (
    <div className='landing-trial-container' >
      <span className='landing-trial-header' >Create your best photos now!</span>
      <span className='landing-trial-subheader' >
        Start 
        rizzer<span className="common-gradient-text-1">.</span>ai 
        with a <span className="common-gradient-text-2">1 week trial </span> 
        and use all the features artificial intelligence has to offer
      </span>
      <button className='common-button landing-trial-button' onClick={() => { navigate('/register') }} >Try It Free Now</button>
    </div>
  ) 
}

const Footer = ({ links }) => {
  return (
    <div className='landing-footer-container' >
      <BlurryEllipse 
        position='absolute'
        width={'1000px'}
        height={'1000px'}
        left={'calc(30% - 1000px/2)'}
        top={'calc(100% - 1000px/4)'}
        rotate={0}
        start_color = 'rgba(219, 0, 255, 0.5)'
        middle_color = 'rgba(219, 0, 255, 0.25)'
        end_color = 'rgba(0, 0, 0, 0)'
        z = {'auto'}
      />
      <div 
      style={
        {
          gridColumnStart: '1',
          gridColumnEnd: '4',
          gridRowStart: '1',
          gridRowEnd: '2'
        }
      }
      className="hide-on-mobile landing-hero-navbar">
        <Logo />
        <NavLinks links={links} />
        <NavRight />
      </div>
      <div 
      style={
        {
          gridColumnStart: '1',
          gridColumnEnd: '4',
          gridRowStart: '1',
          gridRowEnd: '2'
        }
      }
      className="hide-on-desktop landing-hero-navbar-footer">
        <div className="landing-hero-navbar-mobile">
          <Logo />
          <NavRight />
        </div>
        <NavLinks links={links} />
      </div>

      <div 
      style={
        {
          gridColumnStart: '1',
          gridColumnEnd: '2',
          gridRowStart: '2',
          gridRowEnd: '3',
          justifySelf: 'start',
          alignSelf: 'center',
          marginLeft: '50px'
        }
      }
      className='hide-on-mobile landing-footer-bottom' >
        <a href="./"><img src={icon_facebook} alt='' /></a>
        <a href="./"><img src={icon_instagram} alt='' /></a>
      </div>
      <div 
      style={
        {
          gridColumnStart: '2',
          gridColumnEnd: '3',
          gridRowStart: '2',
          gridRowEnd: '3',
          justifySelf: 'center',
          alignSelf: 'center'
        }
      }
      className='hide-on-mobile landing-footer-bottom' >
        <a href="./privacy.html">Privacy Policy</a>
        <a href="./cookies.html">Cookie Notes</a>
      </div>

      <div 
      style={
        {
          gridColumnStart: '1',
          gridColumnEnd: '4',
          gridRowStart: '2',
          gridRowEnd: '3',
          justifySelf: 'center',
          alignSelf: 'center'
        }
      }
      className='hide-on-desktop landing-footer-bottom' >
        <a href="./"><img src={icon_facebook} alt='' /></a>
        <a href="./"><img src={icon_instagram} alt='' /></a>
      </div>
      <div 
      style={
        {
          gridColumnStart: '1',
          gridColumnEnd: '4',
          gridRowStart: '2',
          gridRowEnd: '3'
        }
      }
      className='hide-on-desktop landing-footer-bottom landing-footer-bottom-links' >
        <a href="./privacy.html">Privacy Policy</a>
        <a href="./cookies.html">Cookie Notes</a>
      </div>
    </div>
  ) 
}

const Landing = () => {
  const navigate = useNavigate();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const { ref, percentage } = useScrollPercentage(
    {
      windowScroll: true
    }
  );

  const links = [
    { text: "How it Works", target: "#hiw" },
    { text: "Features", target: "#features" },
    { text: "Pricing", target: "#pricing" },
    { text: "Community", target: "#community" },
  ]

  const handleMobileNavToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  }

  return (
    <div ref={ref} className="landing">
      <div className='landing-container'>
        <Card id='hero' className="landing-hero">

        { /* navbar for desktop */}
        <div className={cx(
            "hide-on-mobile",
            "landing-hero-navbar",
            percentage.vertical > 1 ? "landing-hero-navbar-hidden" : null
            )}>
            <Logo />
            <NavLinks links={links} />
            <NavRight />
          </div>
          <div className={cx(
            "hide-on-mobile",
            "landing-hero-navbar",
            percentage.vertical > 1 ? "landing-hero-navbar-scroll" : "landing-hero-navbar-scroll-hidden"
            )}>
            <Logo />
            <NavLinks links={links} />
            <NavRight />
          </div>

          { /* navbar for mobile */}
          <div className={cx(
            "hide-on-desktop",
            "mobile-landing-hero-navbar"
            )}>
            <Logo />
            <img src={icon_hamburger} alt='' onClick={() => { handleMobileNavToggle() }} />
          </div>
          <div className={cx(
            "hide-on-desktop",
            "mobile-landing-hero-navbar-popup",
            mobileNavOpen ? 
              "mobile-landing-hero-navbar-popup-visible" :
              "mobile-landing-hero-navbar-popup-hidden"
            )}>
            <img className="landing-hero-navbar-exit" src={icon_exit} alt='' onClick={() => { handleMobileNavToggle() }} />
            <Logo />
            <NavLinks links={links} />
            <NavRight />
            <span className='landing-hero-navbar-spacer'></span>
            <span className="landing-hero-content-left-subheading">You can follow us on these social media channels</span>
            <div
              className='landing-hero-content-icons' >
              <a href="./"><img src={icon_facebook} alt='' /></a>
              <span className='landing-hero-navbar-spacer'></span>
              <a href="./"><img src={icon_instagram} alt='' /></a>
            </div>
            <button className="common-button landing-hero-content-left-button" onClick={() => { navigate('/register') }} >Try It Free Now</button>
          </div>

          <div className="landing-hero-content">
            <div className="landing-hero-content-left">
              <span className="landing-hero-content-left-heading">Revolutionize<br/>Your Dating App<br/>Photos</span>
              <span className="landing-hero-content-left-subheading">Our state-of-the-art AI generates images that are optimized for dating apps like Tinder, Hinge, Bumble, and Coffee Meets Bagel. Right images attract right matches.</span>
              <img className="landing-hero-content-left-pencil" src={icon_pencil} alt='' />
              <button className="common-button landing-hero-content-left-button" onClick={() => { navigate('/register') }}>Try It Free Now</button>
            </div>
            <div className="landing-hero-content-right">
              <div className="landing-hero-content-right-container">
                <BlurryEllipse 
                  position='absolute'
                  width={'1000px'}
                  height={'1000px'}
                  left={'-400px'}
                  top={'-500px'}
                  rotate={27.66}
                  start_color = 'rgba(219, 0, 255, 0.5)'
                  middle_color = 'rgba(219, 0, 255, 0.25)'
                  end_color = 'rgba(0, 0, 0, 0)'
                  z = {'0'}
                />
                <img className='landing-hero-content-right-image-tinder' src={icon_tinder} alt='' />
                <img className='landing-hero-content-right-image-hinge' src={icon_hinge} alt='' />
                <img className='landing-hero-content-right-image-heart' src={icon_heart} alt='' />
                <img className='landing-hero-content-right-image-heart-contained' src={icon_heart_contained} alt='' />
              </div>
            </div>
          </div>
        </Card>
        <Card id='hiw' className="landing-hiw-card">
          <div className="landing-hiw-content">
            <div className="landing-hiw-content-left">
              <span className="landing-hiw-content-left-heading">How It Works</span>
              <div className="landing-hiw-content-left-items">
                <HIWItem
                  image={icon_hiw_1}
                  heading={"Upload 25+ photos of yourself"}
                  subheading={"Provide a variety of high-quality images to help our AI understand your features and preferences."}
                  x={0}
                  y={0}
                />
                <HIWItem
                  image={icon_hiw_2}
                  heading={"Our AI works its magic"}
                  subheading={"We use advanced AI algorithms to analyze your photos and generate stunning dating app images tailored to you."}
                  x={0}
                  y={1}
                />
                <HIWItem
                  image={icon_hiw_3}
                  heading={"Choose your favorite images"}
                  subheading={"Browse through the AI-generated photos, pick the best ones, and use them on your favorite dating apps."}
                  x={1}
                  y={0}
                />
                <HIWItem
                  image={icon_hiw_4}
                  heading={["Get more matches show off your rizz!"]}
                  subheading={"Boost your profile's appeal, attract better matches, and share your success with the rizzer.ai community."}
                  x={1}
                  y={1}
                />
              </div>
            </div>
            <div className="landing-hiw-content-right">
              <img className="landing-how-content-right-image-1" src={icon_hiw_photo} alt='' />
              <img className="landing-how-content-right-image-2" src={icon_hiw_match} alt='' />
            </div>
          </div>
        </Card>
        <Card id='features' className="landing-features-card">
          <Features />
        </Card>
        <Card id='pricing' className="landing-pricing-card">
          <Pricing />
        </Card>
        <Card id='community' className="landing-community-card">
          <Community />
        </Card>
        <Card id='testimonials' className="landing-testimonials-card">
          <Testimonials />
        </Card>
        <Card id='trial' className="landing-trial-card">
          <Trial />
        </Card>
        <Card id='footer' className="landing-footer-card">
          <Footer 
            links={links}
          />
        </Card>
      </div>
    </div>
    );
};

export default Landing;