import axios from 'axios'
import AuthService from './auth.service';

import { useSelector, useDispatch } from 'react-redux'

// Store
import store from "../store/store";
import actions from "../store/actions";

axios.interceptors.response.use(undefined, async function axiosRetryInterceptor(err) {
    if(err.response.status === 401 || (err.response.data && err.response.data.message === '401 Unauthorized')) {
        console.log('response - intercepted 401...')

        // get what we think is the current user
        let user = AuthService.getCurrentUser();

        // if user was logged in
        if (user) {
            console.log(user)
            // check and see if token is expired
            if(new Date(user.tokens.access.expires) < Date.now()) {
              console.log('response - access token expired')
              // if so, see if refresh token expired
              if(new Date(user.tokens.refresh.expires) < Date.now()) {
                console.log('response - refresh token expired, user needs to re-authenticate')
                // force log out
                AuthService.logout();
                store.dispatch(actions.userActions.logout())
                // go to login page
                window.location.href = '/login'
              } else {
                console.log('response - getting new access token using refresh token')
                await AuthService.refresh()
                // refresh page
                window.location.reload()
              }
            } else {
              console.log('response - access token not expired, request failed for some other reason')
            }
        }
    }

    return Promise.reject(err)
})