import axios from "axios";
import config from "../config/config"

const API_URL = `${config.api}/models/`;

const getModel = (id) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
    
  return axios.get(
    `${API_URL}${id}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const getAllModels = () => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
    
  return axios.get(
    `${API_URL}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const ModelService = {
  getModel,
  getAllModels
};

export default ModelService; 