// Actions
import { SET_JOBS, SET_TRAINING_JOBS, SET_INFERENCE_JOBS, ADD_TRAINING_JOBS, ADD_INFERENCE_JOBS, REMOVE_TRAINING_JOBS, REMOVE_INFERENCE_JOBS } from "./job.actions";

const initialState = {
  trainingJobs: [],
  inferenceJobs: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_JOBS:
        return {
          ...state,
          trainingJobs: action.jobs.training,
          inferenceJobs: action.jobs.inference
        }

      case SET_TRAINING_JOBS:
        return {
          ...state,
          trainingJobs: action.jobs
        }

      case SET_INFERENCE_JOBS:
        return {
          ...state,
          inferenceJobs: action.jobs
        }

      case ADD_TRAINING_JOBS:
        return {
          ...state,
          trainingJobs: [...state.trainingJobs, ...action.jobs]
        }

      case ADD_INFERENCE_JOBS:
        return {
          ...state,
          inferenceJobs: [...state.inferenceJobs, ...action.jobs]
        }

      case REMOVE_TRAINING_JOBS:
        return {
          ...state,
          trainingJobs: state.trainingJobs.filter(job => !action.jobs.includes(job))
        }

      case REMOVE_INFERENCE_JOBS:
        return {
          ...state,
          inferenceJobs: state.inferenceJobs.filter(job => !action.jobs.includes(job))
        }

      default:
        return state;
    }
  };

export default reducer
