import axios from "axios";
import config from "../config/config"

const API_URL = `${config.api}/images/`;

const getImage = (id) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
    
  return axios.get(
    `${API_URL}${id}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const getImages = (limit = null, skip = null, generated = null, sort = null) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token

  let params = []

  if (limit) {
    params.push(`limit=${limit}`)
  }

  if (skip) {
    params.push(`skip=${skip}`)
  }

  if (generated !== null) {
    params.push(`generated=${generated}`)
  }

  if (sort) {
    params.push(`sort=${sort}`)
  }
    
  return axios.get(
    `${API_URL}${params.length > 0 ? '?' + params.join('&') : ''}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const deleteImage = (id) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token

  return axios.delete(
    `${API_URL}${id}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const ImageService = {
  getImage,
  getImages,
  deleteImage
};

export default ImageService; 