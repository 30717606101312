import { LOGIN, LOGOUT, SET_USER_IMAGE } from "./user.actions";

const initialState = {
    user: null,
    userImage: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN:
        return {
          ...state,
          user: action.user
        };
  
      case LOGOUT:
        return {
          ...state,
          user: null
        };

      case SET_USER_IMAGE:
        return {
          ...state,
          userImage: action.image
        };

      default:
        return state;
    }
  };

export default reducer
