import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

// services
import StripeService from "../../services/stripe.service";
import AuthService from "../../services/auth.service";
import CuponService from "../../services/cupon.service";

// components
import cx from "classnames";

import { BlurryEllipse } from '../../common/common';

import './Account.css';

import icon_person from '../../assets/icons/user.svg';
import icon_lock from '../../assets/icons/lock.svg';
import icon_arrow_right from '../../assets/icons/chevron-right.svg';
import icon_coin from '../../assets/icons/coin.svg'
import icon_logout from '../../assets/icons/log-out.svg';

import preview_1 from "../../assets/test/preview_1.png";
import preview_2 from "../../assets/test/preview_2.png";
import preview_3 from "../../assets/test/preview_3.png";

const NavButton = ({icon, text, arrow, onclick}) => {
  return (
    <div className="account-nav-button" onClick={onclick}>
      <img src={icon} alt='' />
      {text}
      <div className="account-nav-button-spacer"> </div>
      <img src={arrow} alt='' />
    </div>
);
}

const CreditBox = ({icon, credits, onclick}) => {
  return (
    <div className="account-credit-box" onClick={onclick}>
      <img src={icon} alt='' />
      <div className="account-credit-box-text">
        <span className="account-credit-box-heading">Available Credits</span>
        <span className="account-credit-box-content">{credits}</span>
      </div>
      <div className="account-credit-box-spacer"> </div>
      <div className="account-credit-box-button">Upgrade</div>
    </div>
  );
}

const FavoritesBox = ({images, onclick}) => {
  return (
    <div className="account-favorites-box">
      <div className="account-favorites-box-text">
        <span className="account-favorites-box-text-left">My Favorites</span>
        <span className="account-favorites-box-text-right" onClick={onclick}>View All</span>
      </div>
      <div className="account-favorites-box-images">
        {images.filter((e,i) => i < 5).map((image, index) => (
          <img className="account-favorites-box-image" src={image} alt='' />
        ))}
      </div>
    </div>
  );
}

const Account = ({ logOut }) => {
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState([]);

  const user = useSelector(state => state.user.user);
  const userImage = useSelector(state => state.user.userImage);

  const [verificationEmailSendStatus, setVerificationEmailSendStatus] = useState(null);

  const [cupon, setCupon] = useState('');

  const getUserIcon = () => {
    if (userImage) {
      return userImage;
    }
    
    // default
    return null;
  }

  // load subscriptions
  useEffect(() => {
    StripeService.getSubscriptions()
      .then((subscriptions) => {
        setSubscriptions(subscriptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // resend verification email
  const resendVerificationEmail = async () => {
    setVerificationEmailSendStatus(false)

    let response = await AuthService.sendVerificationEmail()

    if(response) setVerificationEmailSendStatus(true)
  }

  // process promo code
  const processCupon = async () => {
    if(!cupon || cupon === '') return

    let response = await CuponService.applyCupon(cupon)

    console.log(response)

    if(response === true) {
      setCupon('')
      window.location.reload();
    } else {
      setCupon('')
    }
  }


  return (
    <div className="account">
        <div className="account-container">
        <BlurryEllipse 
          width={'70%'}
          height={'75%'}
          left={'-5%'}
          top={'10%'}
          rotate={-21.71}
          start_color = 'rgba(135, 32, 148, 0.4)'
          middle_color = 'rgba(135, 32, 148, 0.2)'
          end_color = 'rgba(0, 0, 0, 0)'
        />
        <BlurryEllipse 
          width={'70%'}
          height={'75%'}
          left={'40%'}
          top={'5%'}
          rotate={-21.71}
          start_color = 'rgba(255, 66, 128, 0.4)'
          middle_color = 'rgba(255, 66, 128, 0.2)'
          end_color = 'rgba(0, 0, 0, 0)'
        />

        <div className="account-spacer" />

        <img className={cx(
          "account-user-photo",
          (user && user.user.role === "admin") ? "border-red" : ""
        )} src={getUserIcon()} alt='' />

        {subscriptions.map((subscription, index) => (
        <NavButton 
          icon = {null}
          text = {`Subscription: ${subscription.plan.amount / 100} ${subscription.plan.currency.toUpperCase()} / ${subscription.plan.interval} : CANCEL`}
          arrow = {icon_arrow_right}
          onclick = {async () => { 
            // cancel subscription
            await StripeService.cancelSubscription(subscription.id);

            // refresh page
            window.location.reload();
          }}
        />
        ))}

        {user && !user.user.isEmailVerified && <NavButton 
          icon = {null}
          text = {`Verify Email: Re-send${verificationEmailSendStatus === true ? ' (sent!)' : verificationEmailSendStatus === false ? ' (sending...)' : ''}`}
          arrow = {icon_arrow_right}
          onclick = {resendVerificationEmail}
        />}

        {/* <NavButton 
          icon = {icon_person}
          text = {"Profile Settings"}
          arrow = {icon_arrow_right}
          onclick = {() => { }}
        />

        <NavButton 
          icon = {icon_lock}
          text = {"Profile Settings"}
          arrow = {icon_arrow_right}
          onclick = {() => { }}
        /> */}

        <CreditBox 
          icon = {icon_coin}
          credits = { user ? user.user.membership.credits : 0 }
          onclick = {() => { navigate('/membership') }}
        />

        {/* <FavoritesBox 
          images = {[
            preview_1,
            preview_2,
            preview_3,
            preview_1,
            preview_2,
            preview_3
          ]}
          onclick = {() => { }}
        /> */}

        <form className="account-promo-box" onSubmit={() => { processCupon() }}>
          <span className="account-promo-box-text-left">Promo Code</span>
          <input className="account-promo-box-text-input" type="text" value={cupon} placeholder="Enter Promo Code"
            onChange={(e) => { setCupon(e.target.value) }}
          />
          <button type="submit" className="common-button account-promo-submit">Apply</button>
        </form>

        <NavButton 
          icon = {icon_logout}
          text = {"Log out"}
          arrow = {icon_arrow_right}
          onclick = {logOut}
        />
      </div>
    </div>
    );
};

export default Account;