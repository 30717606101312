import axios from "axios";
import config from "../config/config"

const API_URL = `${config.api}/images/`;

const upload = (files, onUploadProgress, preprocess=false) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
  let formData = new FormData();

  formData.append("upload", files);
  formData.append("preprocess", preprocess);

  return axios.post(API_URL + "uploads", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": "Bearer " + token,
    },
    onUploadProgress,
  });
};

const getFiles = () => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
    
  return axios.get(API_URL,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    });
};

const FileUploadService = {
  upload,
  getFiles,
};

export default FileUploadService; 