import axios from "axios";
import config from "../config/config"

const API_URL = `${config.api}/style/`;

const getStyle = (id) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
    
  return axios.get(
    `${API_URL}${id}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const getAllStyles = () => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token
    
  return axios.get(
    `${API_URL}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    );
};

const StyleService = {
  getStyle,
  getAllStyles
};

export default StyleService; 