
var _config = {}

// environment
if(process.env.REACT_APP_LOCAL === 'local') {
    _config = {
        api: 'http://localhost:3000/v1/',
        url: 'http://localhost:8080/'
    }
} else {
    _config = {
        api: 'https://rizzer.ai:444/v1/',
        url: 'https://rizzer.ai/'
    }
}

// pricing config
_config.pricing = [
    {
        stripe: {
            priceId: process.env.REACT_APP_LOCAL === 'local' ? 
                'price_1NK7nFBBamNJU3U54Jcij63i' : 'price_1NPsBWBBamNJU3U52HhgfUV7',
            mode: process.env.REACT_APP_LOCAL === 'local' ? 
                'subscription' : 'payment'
        },
        type: 'one-time',
        visual: {
            title: 'Affordating',
            price: '$1',
            features: [
                '100 credits',
                'Enough for 2 photos if you\'ve processed already',
                'Access to our exclusive rizz tips Discord server',
                null
            ],
        }
    },
    {
        stripe: {
            priceId: 'price_1NPsCXBBamNJU3U5VfRHJQ6B',
            mode: 'payment'
        },
        type: 'one-time',
        visual: {
            title: 'Rizz',
            price: '$10',
            features: [
                '1100 credits',
                'Train once + generate 10 photos',
                'Exclusive Rizz-only image styles and activities',
                'Everything else in Affordating'
            ],
        }
    },
    {
        stripe: {
            priceId: 'price_1NPsDMBBamNJU3U59zx7ZXFp',
            mode: 'payment'
        },
        type: 'one-time',
        visual: {
            title: 'ENM',
            price: '$25',
            features: [
                '2500 credits',
                'Train many times, or generate 50 photos',
                'Exclusive ENM-only image styles and activities',
                'Everything else in Rizz'
            ],
        }
    },
    {
        stripe: {
            priceId: 'price_1NPsCABBamNJU3U5OtM3yOdV',
            mode: 'subscription'
        },
        type: 'monthly',
        visual: {
            title: 'Affordating',
            price: '$1/month',
            features: [
                '110 credits/month',
                'Enough for 2 photos/month if you\'ve processed already',
                'Access to our exclusive rizz tips Discord server',
                null
            ],
        }
    },
    {
        stripe: {
            priceId: 'price_1NPsCoBBamNJU3U51meIwNbu',
            mode: 'subscription'
        },
        type: 'monthly',
        visual: {
            title: 'Rizz',
            price: '$10/month',
            features: [
                '1100 credits/month',
                'Train once + generate 12 photos monthly',
                'Exclusive Rizz-only image styles and activities',
                'Everything else in Affordating'
            ],
        }
    },
    {
        stripe: {
            priceId: 'price_1NPsDuBBamNJU3U5dAWmyuLf',
            mode: 'subscription'
        },
        type: 'monthly',
        visual: {
            title: 'ENM',
            price: '$25/month',
            features: [
                '2750 credits/month',
                'Train many times, or generate 55 photos monthly',
                'Exclusive ENM-only image styles and activities',
                'Everything else in Rizz'
            ],
        }
    },
]

const config = _config

export default config



