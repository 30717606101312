import axios from "axios";
import config from "../config/config"

const API_URL = `${config.api}/cupon/`;


// apply cupon, returning true if successful and false if not
const applyCupon = (cupon) => {
  const token = JSON.parse(localStorage.getItem('user')).tokens.access.token

  return axios.post(
    `${API_URL}apply`,
    {
      cupon
    },
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    )
    .then((response) => {
      return response.data;
    })
};

const CuponService = {
  applyCupon,
};

export default CuponService; 