import React, { useState, useEffect } from 'react';

import config from '../../config/config';

// services
import StripeService from '../../services/stripe.service';

// components
import cx from 'classnames';
import { HeaderSpacer } from '../../common/common';

import './Membership.css';

const MembershipCards = ({ membershipInfo, sliderText = 'Pay', sliderHeader, onClick }) => {
  const [duration, setDuration] = useState('monthly');

  const toggleDuration = () => {
    if (duration === 'monthly') {
      setDuration('one-time');
    } else {
      setDuration('monthly');
    }
  }

  return (
    [
    <span 
      className="membership-main-heading"
      style={{
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '32px',
        lineHeight: '140%',
    }}>
    {sliderHeader ? [sliderHeader, <br key={'br'} />] : null}
    </span>,
    <span className="membership-duration">
      {sliderText}
      <div 
        className="membership-duration-slider"
        onClick={() => {toggleDuration()}}
      >
        <span className="membership-duration-slider-left">Monthly</span>
        <span className="membership-duration-slider-right">One-Time</span>
        <div 
          className={cx(
            "membership-duration-slider-stylized",
            duration === 'monthly' ? 
              "membership-duration-slider-stylized-left" :
            duration === 'one-time' ?
              "membership-duration-slider-stylized-right" :
              null
            )} 
        />
      </div>
    </span>,
    <span className="membership-price-container">
      {membershipInfo
      .filter((membership) => {
        return membership.type === duration;
      })
      .map((membership, i) => (
      <div key={i} className="membership-price">
        <span className="membership-price-tier">{membership.visual.title}</span>
        <span className="membership-price-cost">{membership.visual.price}</span>
        {membership.visual.features.map((feature, j) => (
        <span key={j} className="membership-price-features">
          {j===membership.visual.features.length-1 ? feature ? '+ ' : '' : '* '}
          <span style={{ textDecoration: j===0 ? 'underline' : 'inhertit' }}>
            {feature}
          </span>
        </span>
        ))}
        <span className="membership-price-spacer" />
        <span 
          className="membership-price-button"
          onClick={() => { onClick(membership) }}
          >Choose {membership.visual.title}</span>
      </div>
    ))}
    </span>
    ].map((item, i) => (
      React.cloneElement(item, { key: i })
    ))
  );
}

const Membership = () => {
  const membershipInfo = config.pricing;

  const onClick = async (membership) => {
    const priceId = membership.stripe.priceId;
    const mode = membership.stripe.mode;

    StripeService.startCheckout(
      priceId, 
      mode,
      `${config.url}account/`, // success url
      `${config.url}account/` // failure url
      )
    .then((redirectUrl) => {
      window.location.href = redirectUrl
    })
  }

  return (
    <div className="membership">
        <HeaderSpacer />
        <span className="membership-heading">Upgrade your <span className="membership-heading-stylized">membership</span> now</span>
        <span className="membership-subheading">Rizzer.AI offer both a monthly subscription and a one-time payment option. You can change your subscription at any time.</span>
        <MembershipCards membershipInfo={membershipInfo} onClick={onClick} />
    </div>
    );
};

export default Membership;
export {
  Membership,
  MembershipCards
}