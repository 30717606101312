// Actions
import { SET_UPLOADED_IMAGES, ADD_UPLOADED_IMAGES, DELETE_GENERATED_IMAGES, 
         SET_GENERATED_IMAGES, ADD_GENERATED_IMAGES, DELETE_UPLOADED_IMAGES
        } from "./image.actions";

const initialState = {
  uploadedImages: null,
  pageNumUploaded: 0,
  pageSizeUploaded: 25,

  generatedImages: null,
  pageNumGenerated: 0,
  pageSizeGenerated: 25
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_UPLOADED_IMAGES:
        return {
          ...state,
          uploadedImages: action.images,
          pageNumUploaded: 1
        }

      case ADD_UPLOADED_IMAGES:
        return {
          ...state,
          uploadedImages: [...state.uploadedImages, ...action.images],
          pageNumUploaded: action.images.length !== 0 ? state.pageNumUploaded + 1 : state.pageNumUploaded
        }

      case DELETE_UPLOADED_IMAGES:
        return {
          ...state,
          uploadedImages: state.uploadedImages.filter((image) => !action.images.includes(image.id))
        }

      case SET_GENERATED_IMAGES:
        return {
          ...state,
          generatedImages: action.images,
          pageNumGenerated: 1
        }

      case ADD_GENERATED_IMAGES:
        return {
          ...state,
          generatedImages: [...state.generatedImages, ...action.images],
          pageNumGenerated: action.images.length !== 0 ? state.pageNumGenerated + 1 : state.pageNumGenerated
        }

      case DELETE_GENERATED_IMAGES:
        return {
          ...state,
          generatedImages: state.generatedImages.filter((image) => !action.images.includes(image.id))
        }

      default:
        return state;
    }
  };

export default reducer
