// Actions
import { SET_MODELS, ADD_MODELS } from "./model.actions";

const initialState = {
  models: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_MODELS:
        return {
          ...state,
          models: action.models
        }

      case ADD_MODELS:
        return {
          ...state,
          models: [...state.models, ...action.models]
        }

      default:
        return state;
    }
  };

export default reducer
