// Services
import JobService from "../../services/job.service";

// Exports
export const SET_JOBS = "SET_JOBS";
export const SET_TRAINING_JOBS = "SET_TRAINING_JOBS";
export const SET_INFERENCE_JOBS = "SET_INFERENCE_JOBS";
export const ADD_TRAINING_JOBS = "ADD_TRAINING_JOBS";
export const ADD_INFERENCE_JOBS = "ADD_INFERENCE_JOBS";
export const REMOVE_TRAINING_JOBS = "REMOVE_TRAINING_JOBS";
export const REMOVE_INFERENCE_JOBS = "REMOVE_INFERENCE_JOBS";

export const setJobs = (trainingJobs, inferenceJobs) => {
    return {
        type: SET_JOBS,
        jobs: {
            training: trainingJobs,
            inference: inferenceJobs
        }
    }
}

export const setTrainingJobs = (jobs) => {
    return {
        type: SET_TRAINING_JOBS,
        jobs: jobs
    }
}

export const setInferenceJobs = (jobs) => {
    return {
        type: SET_INFERENCE_JOBS,
        jobs: jobs
    }
}

export const addTrainingJobs = (jobs) => {
    return {
        type: ADD_TRAINING_JOBS,
        jobs: jobs
    }
}

export const addInferenceJobs = (jobs) => {
    return {
        type: ADD_INFERENCE_JOBS,
        jobs: jobs
    }
}

export const removeTrainingJobs = (jobs) => {
    return {
        type: REMOVE_TRAINING_JOBS,
        jobs: jobs
    }
}

export const removeInferenceJobs = (jobs) => {
    return {
        type: REMOVE_INFERENCE_JOBS,
        jobs: jobs
    }
}

export const loadJobs = async (dispatch, getState) => {
    try {
        let trainingJobs = (await JobService.getAllTrainingJobs()).data
                                              .filter((j) => j.state === "running" || j.state === "pending" || j.state === "processing" || j.state === "starting")
        let inferenceJobs = (await JobService.getAllInferenceJobs()).data
                                              .filter((j) => j.state === "running" || j.state === "pending" || j.state === "processing" || j.state === "starting")
      
        trainingJobs = trainingJobs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        inferenceJobs = inferenceJobs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      
      dispatch(setJobs(trainingJobs, inferenceJobs))
    } catch (err) {
      //
    }
}

export const loadTrainingJobs = async (dispatch, getState) => {
    try {
        const trainingJobs = (await JobService.getAllTrainingJobs()).data
                                              .filter((j) => j.state === "running" || j.state === "pending" || j.state === "processing" || j.state === "starting")
      
        const jobs = trainingJobs
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      
      dispatch(setTrainingJobs(jobs))
    } catch (err) {
      //
    }
}

export const loadInferenceJobs = async (dispatch, getState) => {
    try {
        const inferenceJobs = (await JobService.getAllInferenceJobs()).data
                                              .filter((j) => j.state === "running" || j.state === "pending" || j.state === "processing" || j.state === "starting")
      
        const jobs = inferenceJobs
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      
      dispatch(setInferenceJobs(jobs))
    } catch (err) {
      //
    }
}

const actions = {
    setJobs,
    setTrainingJobs,
    setInferenceJobs,

    addTrainingJobs,
    addInferenceJobs,

    removeTrainingJobs,
    removeInferenceJobs,

    loadJobs,
    loadTrainingJobs,
    loadInferenceJobs
}

export default actions