import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./App.css";

// store
import { Provider } from "react-redux";
import DevTools from "./components/DevTools/DevTools";
import store from "./store/store";
import actions from "./store/actions";

// services
import Interceptors from "./services/interceptors"; // need to import this if we want to intercept requests
import AuthService from "./services/auth.service";

// components
import Navbar from "./components/Navbar/Navbar";

import Login from "./components/Authentication/Login";
import Register from "./components/Authentication/Register";

import Upload from "./components/Upload/Upload";
import Generate from "./components/Generate/Generate";
import Account from "./components/Account/Account";
import Membership from "./components/Account/Membership";
import Landing from "./components/Landing/Landing";

// other
import ReactGA from "react-ga4";
import EventBus from "./common/EventBus";

// main app
const App = () => {
  // Google Analytics
  useEffect(() => {
    // google analytics
    const GA4_TRACKING_ID = "G-9R4ZYFH46T"
    ReactGA.initialize(GA4_TRACKING_ID)
    // ReactGA.set({ page: window.location.pathname, query: window.location.search })
    // ReactGA.send({ hitType: "pageview" })
  }, []);

  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  // load user from cookies on mount
  //   if token is expired, see if refresh token can be used
  //   to get new access token
  useEffect(() => {
    const initUser = async () => {
      let user = AuthService.getCurrentUser();

      // if user was logged in
      if (user) {
        try {
          // check and see if token is expired
          if(new Date(user.tokens.access.expires) < Date.now()) {
            console.log('access token expired')
            // if so, see if refresh token expired
            if(new Date(user.tokens.refresh.expires) < Date.now()) {
              console.log('refresh token expired, user needs to re-authenticate')
              // if so, force log out
              logOut()
            } else {
              console.log('getting new access token using refresh token')
              user = await AuthService.refresh()
            }
          } else {
            console.log('access token not expired')
          }

          // get the latest user data
          user = await AuthService.update();

          // save user in the local state
          setCurrentUser(user)
          setIsAdmin(user.user.role === 'admin')

          // make sure its set in global store too
          store.dispatch(actions.userActions.login(user))
        } catch (err) {
          console.log(err)
        }
      }

      EventBus.on("logout", () => {
        logOut();
      });
    }

    // run the init function
    initUser()

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  // things to load whenever the user changes
  useEffect(() => {
    if(currentUser) {
      store.dispatch(actions.modelActions.loadModels)
      store.dispatch(actions.jobActions.loadJobs)
    }
  }, [currentUser])

  const logOut = () => {
    AuthService.logout();

    setIsAdmin(false);
    setCurrentUser(undefined);

    store.dispatch(actions.userActions.logout())

    // go to login page
    window.location.href = '/login'
  };

  // handle verify-email route forwarding
  useEffect(() => {
    if(window.location.pathname.startsWith('/verify-email')) {
      const token = window.location.search.split('token=').slice(-1)[0].split('&')[0]
      AuthService.verifyEmail(token)
        .then((response) => {
          if(response) {
            window.location.href = '/account'
          } else {
            window.location.href = '/'
          }
        })
    }
  }, [])

  const maybeNavbar = () => {
    if (AuthService.getCurrentUser() && (
      window.location.pathname.startsWith('/generate') ||
      window.location.pathname.startsWith('/upload') ||
      window.location.pathname.startsWith('/account') ||
      window.location.pathname.startsWith('/membership')
    )) {
      return <Navbar currentUser={currentUser} isAdmin={isAdmin} logOut={() => logOut()} />;
    } else {
      return null
    }
  }

  const maybeAlertbar = () => {
  if (AuthService.getCurrentUser() && currentUser && !currentUser.user.isEmailVerified && (
      window.location.pathname.startsWith('/generate') ||
      window.location.pathname.startsWith('/upload') ||
      window.location.pathname.startsWith('/account') ||
      window.location.pathname.startsWith('/membership')
    )) {
      return (
        <div 
          className="maybe-alert-bar"
          style={{
            backgroundColor: '#ffc107',
            color: '#000',
        }}>
          <strong style={{
            color: 'red'
          }}>Alert!:</strong> You need to verify your email before running any models!
          <button type="button" className="common-button maybe-alert-bar-button" onClick={() => { window.location.href='/account' }} 
          style={{
            backgroundColor: '#000',
            color: '#fff',
          }}>Verify</button>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <Provider store={store}>
      <DevTools />
      {maybeNavbar()}
      {maybeAlertbar()}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/generate" element={AuthService.getCurrentUser() ? <Generate /> : <Login />} />
        <Route path="/upload" element={AuthService.getCurrentUser() ? <Upload /> : <Login />} />
        <Route path="/account" element={AuthService.getCurrentUser() ? <Account logOut={() => logOut()} /> : <Login />} />
        <Route path="/membership" element={AuthService.getCurrentUser() ? <Membership /> : <Login />} />
        <Route path="*" element={<Landing />} />
      </Routes>
    </Provider>
  );
};

export default App;
