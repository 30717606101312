import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import OAuth2Login from 'react-simple-oauth2-login';

import actions from "../../store/actions";

import AuthService from "../../services/auth.service";

import { BlurryEllipse, Input, Button } from '../../common/common';

import './Login.css';

import icon_google from '../../assets/icons/google.svg';
import icon_facebook from '../../assets/icons/facebook.svg';

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    // const salt = '$2a$10$CwTycUXWue0Thq9StjUM0u'
    // const password = bcrypt.hashSync(e.target.value, salt)
    const password = e.target.value
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage('');
    setLoading(true);

    if (1===1) {
      AuthService.login(email, password).then(
        () => {
          dispatch(actions.userActions.login(AuthService.getCurrentUser()));

          navigate("/upload");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const oauth2_onSuccessGoogle = (response) => {
    const code = response.code

    setMessage('');
    setLoading(true);

    if (1===1) {
      AuthService.loginGoogle(
        code,
        process.env.REACT_APP_LOCAL === 'local' ? 'http://localhost:8080' : 'https://rizzer.ai/login'
        ).then(
        () => {
          dispatch(actions.userActions.login(AuthService.getCurrentUser()));

          navigate("/upload");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };
  const oauth2_onFailure = response => console.error(response);

  return (
    <form onSubmit={handleLogin} className="login">
        <div className='login-spacer' />
        <BlurryEllipse 
          width={'70%'}
          height={'75%'}
          left={'-5%'}
          top={'10%'}
          rotate={27.66}
          start_color = 'rgba(219, 0, 255, 0.5)'
          middle_color = 'rgba(219, 0, 255, 0.25)'
          end_color = 'rgba(0, 0, 0, 0)'
        />
        <BlurryEllipse 
          width={'50%'}
          height={'60%'}
          left={'-5%'}
          top={'50%'}
          rotate={-21.72}
          start_color = 'rgba(195, 49, 138, 0.5)'
          middle_color = 'rgba(195, 49, 138, 0.25)'
          end_color = 'rgba(0, 0, 0, 0)'
        />
        <BlurryEllipse 
          width={'50%'}
          height={'50%'}
          left={'20%'}
          top={'50%'}
          rotate={-21.72}
          start_color = 'rgba(255, 66, 128, 0.4)'
          middle_color = 'rgba(255, 66, 128, 0.2)'
          end_color = 'rgba(0, 0, 0, 0)'
        />
        <div className='login-content'>
          <div className='login-heading-container'>
            <span className="login-heading">Revolutionize Your <span className="login-heading-stylized">Dating Photos</span></span>
            <span className="login-subheading">Rizzer.AI tailors each generated photo to your unique<br/>features and preferences, ensuring your profile<br/>stands out.</span>
          </div>
          <div className="login-card">
            <span className='login-card-heading-container'>
              <span className='login-card-heading'>Sign In</span>
              <span className='login-card-subheading'>New member? <a href='./register'>Register</a></span>
            </span>
            <Input 
              type="text"
              label='Email' 
              onChange={onChangeEmail}
            />
            <Input 
              type="password"
              label='Password' 
              onChange={onChangePassword}
            />
            <div className='login-card-row'>
              <label className='login-card-checkbox'>
                <input type="checkbox" />
                <span className='login-card-row-checkbox-text'>Remember me</span>
              </label>
              <span className='login-card-spacer' />
              <a href='./' className='login-card-row-text-stylized'>Forgot password?</a>
            </div>
            <Button 
              icon='' 
              text='Sign In' 
              highlight={true} 
              type='submit'
            />
            {message !== '' ? <span style={{ color: 'red' }} >{message}</span> : null}
            <span className='login-card-row'>
              <span className='login-card-white-bar' />
              <span className='login-card-row-text'>or</span>
              <span className='login-card-white-bar' />
            </span>
            <OAuth2Login
              authorizationUrl="https://accounts.google.com/o/oauth2/auth"
              responseType="code"
              clientId="902000871718-n5dctp51rrl4ftnhuonh7n7gqf5c3dah"
              redirectUri={process.env.REACT_APP_LOCAL === 'local' ? 'http://localhost:8080' : 'https://rizzer.ai/login'}
              scope='https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid'
              onSuccess={oauth2_onSuccessGoogle}
              onFailure={oauth2_onFailure}
              render={({ onClick }) => {return (
                  <Button 
                    icon={icon_google} 
                    text='Sign In with Google'
                    onClick={onClick}
                  />
                )}
              }
            />
            {/* <OAuth2Login
              authorizationUrl="https://www.facebook.com/v17.0/dialog/oauth"
              responseType="code"
              clientId="104228392707090"
              redirectUri="https://redirectmeto.com/http://localhost:8080"
              scope='email'
              auth_type='rerequest'
              onSuccess={(r) => console.log(r)}
              onFailure={oauth2_onFailure}
              render={({ onClick }) => {return [
                  <Button 
                    icon={icon_facebook} 
                    text='Sign In with Facebook'
                    onClick={onClick}
                  />
                ]}
              }
            /> */}
          </div>
        </div>
    </form>
    );
};

export default Login;