import { compose } from 'redux';
import thunkMiddleware from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'

import DevTools from '../components/DevTools/DevTools';

import userReducer from "./user/user.reducer";
import imageReducer from "./image/image.reducer";
import modelReduer from "./model/model.reducer";
import styleReducer from "./style/style.reducer";
import jobReducer from "./job/job.reducer";

const enhancer = compose(
    // DevTools.instrument()
);

export default configureStore({
    reducer: {
        user: userReducer,
        image: imageReducer,
        model: modelReduer,
        style: styleReducer,
        job: jobReducer
    },
    middleware: [thunkMiddleware],
    enhancers: [enhancer]
})
