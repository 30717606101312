import userActions from "./user/user.actions";
import imageActions from "./image/image.actions";
import modelActions from "./model/model.actions";
import styleActions from "./style/style.actions";
import jobActions from "./job/job.actions";

const actions = {
    userActions,
    imageActions,
    modelActions,
    styleActions,
    jobActions
}

export default actions