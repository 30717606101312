import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';

// Services
import FileUploadService from "../../services/fileupload.service";

// Components
import { HeaderSpacer, ImageGallery, Button } from '../../common/common';
import "./UploadBlank.css";

const ContentCard = ({number, header, text}) => {
  return (
    <div className="uploadblank-content-card">
      <div className="uploadblank-content-card-number">{number}</div>
      <div className="uploadblank-content-card-text-container">
        <div className="uploadblank-content-card-title">{header}</div>
        <div className="uploadblank-content-card-text">{text}</div>
      </div>
    </div>
  );
}

const UploadBlank = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // images
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const progressInfosRef = useRef(null);
  const [message, setMessage] = useState([]);

  // input
  const [inputElement, setInputElement] = useState(null); // input element

  const upload = (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return FileUploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      )
      setProgressInfos({ val: _progressInfos });
    }, true)
      .then((res) => {
        setMessage((prevMessage) => [
          "Uploaded the image successfully"
        ])
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });

        setMessage((prevMessage) => [
          "Could not upload the image",
        ])
      })
  }

  const uploadImages = (event) => {
    // collect file objects depending on type of event
    let files = [];

    if(event.target.files) {
      files = Array.from(event.target.files);
    } else {
      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...event.dataTransfer.items].forEach((item, i) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file" && item.type.includes("image")) {
            const file = item.getAsFile();
            files.push(file)
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...event.dataTransfer.files].forEach((file, i) => {
          if(file.type.includes("image")) {
            file.push(file)
          }
        });
      }
    }

    // get ready to upload
    let _progressInfos = files.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }))

    progressInfosRef.current = {
      val: _progressInfos,
    }

    const uploadPromises = files.map((file, i) => upload(i, file))

    Promise.all(uploadPromises)
      .then(() => {
        console.log("finished uploading files")
        window.location.reload()
      })
  }

  return (
    <div className="uploadblank">
      <HeaderSpacer />
      <div className="uploadblank-bg">
        <div className="uploadblank-bg-ellipse"></div>
        <div className="uploadblank-bg-rectangle"></div>
      </div>
      <div className="uploadblank-content">
        <div className="uploadblank-content-left">
          <ContentCard number={1} header="Upload" text="Upload 25 or more of your best photos." />
          <ContentCard number={2} header="Model preparation" text="Takes no longer than 30 minutes." />
        </div>
        <div className="uploadblank-content-center hide-on-mobile">
          <div className="uploadblank-content-center-grid">
            <div className="uploadblank-content-center-preview-base uploadblank-content-center-preview-1" />
            <div className="uploadblank-content-center-preview-base uploadblank-content-center-preview-2-1" />
            <div className="uploadblank-content-center-preview-base uploadblank-content-center-preview-2-2" />
            <div className="uploadblank-content-center-iphone" />
            <div className="uploadblank-content-center-preview-base uploadblank-content-center-preview-3-1" />
            <div className="uploadblank-content-center-preview-base uploadblank-content-center-preview-3-2" />
            <div className="uploadblank-content-center-preview-base uploadblank-content-center-preview-4" />
          </div>
        </div>
        <div className="uploadblank-content-right">
          <ContentCard number={3} header="Generate options" text="Select the resolution, style and the number of images." />
          <ContentCard number={4} header="Image generation" text="Images will be generated in 2-4 minutes." />
        </div>
        <div className="uploadblank-content-bottom hide-on-mobile">
          <div className="uploadblank-content-bottom-heading">Upload Photos</div>
          <div 
            className="uploadblank-content-bottom-upload-container"
            onDragOver={(e) => { e.preventDefault(); }}
            onDragLeave={(e) => { e.preventDefault(); }}
            onDrop={(e) => {
              e.preventDefault();
              uploadImages(e)
            }}
          >
            <div className="uploadblank-content-bottom-upload-image" />
            <div className="uploadblank-content-bottom-upload-text">Drag & Drop 25 photos here</div>
            <div className="uploadblank-content-bottom-upload-text-small">or</div>
            <Button 
            className="uploadblank-content-bottom-upload-button"
            text="Browse..."
            color="transparent"
            onClick={() => inputElement.click()} /
            >
            <input
              ref={input => setInputElement(input)}
              style={{ display: 'none'}}
              type="file"
              multiple
              accept="image/*"
              onChange={(e) => {
                uploadImages(e)
              }}
            />
          </div>
        </div>
        <div className="uploadblank-content-bottom hide-on-desktop">
          <Button 
            className="uploadblank-content-bottom-upload-button"
            text="Upload Photos"
            highlight={true}
            onClick={() => inputElement.click()} 
          />
          <input
            ref={input => setInputElement(input)}
            style={{ display: 'none'}}
            type="file"
            multiple
            accept="image/*"
            onChange={(e) => {
              uploadImages(e)
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadBlank;