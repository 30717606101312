// Actions
import { SET_STYLES } from "./style.actions";

const initialState = {
  styles: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_STYLES:
        return {
          ...state,
          styles: action.styles
        }

      default:
        return state;
    }
  };

export default reducer
