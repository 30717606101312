// Services
import ImageService from "../../services/image.service";

// Exports
export const SET_UPLOADED_IMAGES = "SET_UPLOADED_IMAGES";
export const ADD_UPLOADED_IMAGES = "ADD_UPLOADED_IMAGES";
export const DELETE_UPLOADED_IMAGES = "DELETE_UPLOADED_IMAGES";
export const SET_GENERATED_IMAGES = "SET_GENERATED_IMAGES";
export const ADD_GENERATED_IMAGES = "ADD_GENERATED_IMAGES";
export const DELETE_GENERATED_IMAGES = "DELETE_GENERATED_IMAGES";

export const setUploadedImages = (images) => {
    return {
        type: SET_UPLOADED_IMAGES,
        images: images
    }
}

export const addUploadedImages = (images) => {
    return {
        type: ADD_UPLOADED_IMAGES,
        images: images
    }
}

export const deleteUplaoedImages = (images) => {
    return {
        type: DELETE_UPLOADED_IMAGES,
        images: images
    }
}

export const setGeneratedImages = (images) => {
    return {
        type: SET_GENERATED_IMAGES,
        images: images
    }
}

export const addGeneratedImages = (images) => {
    return {
        type: ADD_GENERATED_IMAGES,
        images: images
    }
}

export const deleteGeneratedImages = (images) => {
    return {
        type: DELETE_GENERATED_IMAGES,
        images: images
    }
}

export const loadFirstPageUploaded = async (dispatch, getState) => {
    const { pageSizeUploaded: pageSize } = getState().image;

    try {
      let userImages = await ImageService.getImages(pageSize, 0, false);
      
      userImages = userImages.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .filter((image) => image.generated===false)
          .map((image) => {return {
            id: image._id, 
            src: image.fileLink, 
            key: image.s3_key, 
            preprocess: image.preprocess, 
            preprocessing: image.preprocessing, 
            createdAt: image.createdAt
        }});
      
      dispatch({ type: SET_UPLOADED_IMAGES, images: userImages })
    } catch (err) {
      //
    }
  }

export const loadNextPageUploaded = async (dispatch, getState) => {
    const { pageNumUploaded: pageNum, pageSizeUploaded: pageSize } = getState().image;

    try {
        let userImages = await ImageService.getImages(pageSize, pageNum*pageSize, false);
        
        userImages = userImages.data
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .filter((image) => image.generated===false)
            .map((image) => {return {
                id: image._id, 
                src: image.fileLink, 
                key: image.s3_key, 
                preprocess: image.preprocess, 
                preprocessing: image.preprocessing, 
                createdAt: image.createdAt
            }});
        
        dispatch({ type: ADD_UPLOADED_IMAGES, images: userImages })
    } catch (err) {
        //
    }
}

export const loadFirstPageGenerated = async (dispatch, getState, sort = false) => {
    const { pageSizeGenerated: pageSize } = getState().image;

    try {
      let userImages = await ImageService.getImages(pageSize, 0, true, sort ? 1 : -1);
      
      userImages = userImages.data
          .sort((a, b) => !sort ? new Date(b.createdAt) - new Date(a.createdAt) : new Date(a.createdAt) - new Date(b.createdAt))
          .filter((image) => image.generated===true)
          .map((image) => {return {
            id: image._id, 
            src: image.fileLink, 
            key: image.s3_key, 
            preprocess: image.preprocess, 
            preprocessing: image.preprocessing, 
            createdAt: image.createdAt
        }});
      
      dispatch({ type: SET_GENERATED_IMAGES, images: userImages })
    } catch (err) {
      //
    }
  }

export const loadNextPageGenerated = async (dispatch, getState, sort = false) => {
    const { pageNumGenerated: pageNum, pageSizeGenerated: pageSize } = getState().image;

    try {
        let userImages = await ImageService.getImages(pageSize, pageNum*pageSize, true, sort ? 1 : -1);
        
        userImages = userImages.data
            .sort((a, b) => !sort ? new Date(b.createdAt) - new Date(a.createdAt) : new Date(a.createdAt) - new Date(b.createdAt))
            .filter((image) => image.generated===true)
            .map((image) => {return {
                id: image._id, 
                src: image.fileLink, 
                key: image.s3_key, 
                preprocess: image.preprocess, 
                preprocessing: image.preprocessing, 
                createdAt: image.createdAt
            }});
        
        dispatch({ type: ADD_GENERATED_IMAGES, images: userImages })
    } catch (err) {
        //
    }
}

export const loadFirstPageGeneratedFunc = (sort = false) => {
    return async (dispatch, getState) => {
        return loadFirstPageGenerated(dispatch, getState, sort)
    }
}

export const loadNextPageGeneratedFunc = (sort = false) => {
    return async (dispatch, getState) => {
        return loadNextPageGenerated(dispatch, getState, sort)
    }
}

const actions = {
    setUploadedImages,
    addUploadedImages,
    deleteUplaoedImages,
    setGeneratedImages,
    addGeneratedImages,
    deleteGeneratedImages,

    loadFirstPageUploaded,
    loadNextPageUploaded,
    loadFirstPageGenerated,
    loadNextPageGenerated,

    loadFirstPageGeneratedFunc,
    loadNextPageGeneratedFunc,
}

export default actions