import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import actions from "../../store/actions";

import ImageService from "../../services/image.service";

import cx from "classnames";
import "./Navbar.css";

import icon_coin from '../../assets/icons/coin.svg'
import icon_person from '../../assets/icons/user.svg';
import icon_card from '../../assets/icons/card-light.svg';
import icon_logout from '../../assets/icons/log-out-red.svg';
import icon_hamburger from '../../assets/icons/hamburger.svg';

/* Logo: rizzer.ai */
const Logo = () => {
  return (
    <div className="navbar-title"><a href="./">rizzer<span className="navbar-title-dot">.</span>ai</a></div>
  );
}

/* Navigation Links */
const NavLinks = ({ links }) => {
  const navigate = useNavigate();

  function handleClick(target) {
    if(target.includes('?')) {
      const [path, query] = target.split('?');
      navigate({
        pathname: path,
        search: '?'+query
      })
    } else {
      navigate(target)
    }
  }

  return (
    <div className="navbar-links">
      {links.map((link, index) => (
        <div key={index} className="navbar-link-container">
          <div 
            className={
              cx(
                "navbar-link",
                link.selected ? "navbar-link-selected" : ""
              )}
            onClick={() => handleClick(link.target)}
          >
            {link.text}
          </div>
          {link.notification && <div className="navbar-link-notification" />}
        </div>
      ))}
    </div>
  );
}

/* User Settings */
const NavUser = ({ userIcon, isAdmin, userCredits, modalSettings }) => {
  const navigate = useNavigate();

  const [dropdownVisible, setDropdownVisible] = useState(modalSettings.initVisible);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownVisible(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function handleClick(onClick, target) {
    if(onClick) onClick();

    navigate(target);
    setDropdownVisible(false);
  }

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  }

  return (
    <div ref={wrapperRef} className="navbar-user">
      <div className="navbar-user-credits hide-on-mobile">
        <div className="navbar-user-credits-icon">
          <img src={icon_coin} alt=''/>
        </div>
        <div className="navbar-user-credits-info">
          <div className="navbar-user-credits-info-top">Available Credits</div>
          <div className="navbar-user-credits-info-bottom">{userCredits}</div>
        </div>
      </div>
      { /* Non mobile */ }
      <div className={cx(
        "hide-on-mobile",
        "navbar-user-photo",
        isAdmin ? "border-red border-enable-hover" : ""
        )} onClick={() => toggleDropdown()}>
        <img src={userIcon} alt='' />
      </div>
      { /* Mobile */ }
      <div className={cx(
        "hide-on-desktop",
        "navbar-user",
        )} onClick={() => toggleDropdown()}>
        <img src={icon_hamburger} alt='' />
      </div>
      { dropdownVisible &&
      <div className="navbar-user-dropdown">
        <div className="navbar-user-dropdown-heading">
          <img className={cx(
            "navbar-user-dropdown-photo",
            isAdmin ? "border-red" : ""
            )} src={userIcon} alt='' />
          <div className="navbar-user-dropdown-info">
            <span className="navbar-user-dropdown-info-name">{modalSettings.name}</span>
            <span className="navbar-user-dropdown-info-email">{modalSettings.email}</span>
          </div>
        </div>
        <div className="navbar-user-dropdown-items">
          {modalSettings.items.map((item, index) => (
            <div key={index} className="navbar-user-dropdown-item" onClick={() => handleClick(item.onClick, item.target)}>
              <img className="navbar-user-dropdown-item-icon" src={item.icon} alt='' />
              <span className={cx(
                "navbar-user-dropdown-item-text",
                item.red ? "text-red" : "text-white"
              )}>{item.text}</span>
            </div>
          ))}
        </div>
      </div>
      }
    </div>
  );
}

/* Navbar */
const Navbar = ({ currentUser, isAdmin, logOut }) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const userImage = useSelector(state => state.user.userImage);

  const [generateToggle, setGenerateToggle] = useState(false);

  // get generateToggle value (if it exists) from query string
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const generateToggle_ = params.get('open');
    if(generateToggle_ === 'true') {
      setGenerateToggle(true);
    } else if(generateToggle_ === 'false') {
      setGenerateToggle(false);
    } else {
      setGenerateToggle(true);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchExistingImages = async () => {
      try {
        const userImages = await ImageService.getImages(1, 0, false);
       
        const existingImages = userImages.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .filter((image) => image.generated===false)
          .map((image) => ({ id: image._id, url: image.fileLink, key: image.s3_key }));

        if(existingImages.length > 0) {
          dispatch(actions.userActions.setUserImage(existingImages[0].url))
        }
      } catch (err) {
        //
      }
    };

    if(!userImage) fetchExistingImages();
  });

  const getUserIcon = () => {
    if (userImage) {
      return userImage;
    }
    
    // default
    return null
  }

  return (
    [<div key={'desktop'} className="navbar hide-on-mobile">
      <Logo />
      <NavLinks links={
        [
          { 
            text: "Home",
            target: "/generate",
            notification: false,
            selected: location.pathname === "/generate"
          },
          { 
            text: "Upload",
            target: "/upload",
            notification: false,
            selected: location.pathname === "/upload"
          }
        ]
      } />
      <NavUser 
        userIcon={getUserIcon()}
        isAdmin={isAdmin}
        userCredits={ user ? user.user.membership.credits : 0 }
        logoutTarget={""}
        modalSettings={
          { 
            initVisible: false,
            name: user ? user.user.name : '',
            email: user ? user.user.email : '',
            items: [
              {
                icon: icon_person,
                text: "Account settings",
                target: "/account"
              },
              {
                icon: icon_card,
                text: "Manage membership",
                target: "/membership"
              },
              {
                icon: icon_logout,
                text: "Log out",
                target: "/",
                onClick: logOut,
                red: user ? user.user.role === 'admin' : ''
              }
            ]
          }
        }
      />
    </div>,
    <div key={'mobile'} className="navbar hide-on-desktop">
      <NavUser 
        userIcon={getUserIcon()}
        isAdmin={isAdmin}
        userCredits={ user ? user.user.membership.credits : 0 }
        logoutTarget={""}
        modalSettings={
          { 
            initVisible: false,
            name: user ? user.user.name : '',
            email: user ? user.user.email : '',
            items: [
              {
                icon: icon_person,
                text: "Account settings",
                target: "/account"
              },
              {
                icon: icon_card,
                text: "Manage membership",
                target: "/membership"
              },
              {
                icon: icon_logout,
                text: "Log out",
                target: "/",
                onClick: logOut,
                red: user ? user.user.role === 'admin' : ''
              }
            ]
          }
        }
      />
      <NavLinks links={
        [
          { 
            text: "Home",
            target: `/generate?open=${!generateToggle}`,
            notification: false,
            selected: location.pathname === "/generate"
          },
          { 
            text: "Upload",
            target: "/upload",
            notification: false,
            selected: location.pathname === "/upload"
          }
        ]
      } />
      <div className="navbar-title"><a href="./">rizzer<span className="navbar-title-dot">.</span>ai</a></div>
    </div>]
  );
};

export default Navbar;