import axios from "axios";
import config from "../config/config"

const API_URL = `${config.api}/auth/`;
const API_URL_USER = `${config.api}/users/`;

const register = (name, email, password) => {
  return axios.post(API_URL + 'register', {
    name,
    email,
    password,
  });
};

const login = (email, password) => {
  return axios
    .post(API_URL + 'login', {
      email,
      password,
    })
    .then((response) => {
      if (response.data.tokens.access.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
};

const loginGoogle = (code, redirect='http://localhost:8080') => {
  return axios
    .post(API_URL + 'loginOauth', {
      googleCode: code,
      redirect: redirect,
    })
    .then((response) => {
      if (response.data.tokens.access.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
};

const loginFacebook = (code, redirect='http://localhost:8080') => {
  return axios
    .post(API_URL + 'loginOauth', {
      facebookCode: code,
      redirect: redirect,
    })
    .then((response) => {
      if (response.data.tokens.access.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
};

const refresh = () => {
  let user = JSON.parse(localStorage.getItem('user'));
  let refreshToken = user.tokens.refresh.token;

  return axios
    .post(API_URL + 'refresh-tokens', {
      'refreshToken': refreshToken,
    })
    .then((response) => {
      if (response.data.access.token) {
        user.tokens = response.data
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
};

// get updated user object
const update = () => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.tokens.access.token;

  if(!token) {
    return null
  }

  if(!user) {
    return null
  }

  return axios
    .get(API_URL_USER + user.user.id, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      if (response.data) {
        user.user = response.data
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
};


const logout = () => {
  localStorage.removeItem('user');
};

const setCurrentUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const getCurrentUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    return null;
  }
};

// send verification email
const sendVerificationEmail = async () => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.tokens.access.token;

  if(!token) {
    return null
  }

  if(!user) {
    return null
  }
  
  return axios
    .post(API_URL + 'send-verification-email', {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      // if successful response, return true. otherwise, return false
      return true
    })
    .catch((error) => {
      return false
    });
};

// verify email
const verifyEmail = async (token) => {
  return axios
    .get(API_URL + 'verify-email', {
      params: {
        token: token
      }
    })
    .then((response) => {
      // if successful response, return true. otherwise, return false
      return true
    })
    .catch((error) => {
      return false
    });
};

const AuthService = {
  register,
  login,
  loginGoogle,
  loginFacebook,
  logout,
  refresh,
  update,
  getCurrentUser,
  setCurrentUser,
  sendVerificationEmail,
  verifyEmail,
};

export default AuthService;
