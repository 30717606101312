// Services
import ModelService from "../../services/model.service";

// Exports
export const SET_MODELS = "SET_MODELS";
export const ADD_MODELS = "ADD_MODELS";

export const setModels = (models) => {
    return {
        type: SET_MODELS,
        models: models
    }
}

export const addModels = (models) => {
    return {
        type: ADD_MODELS,
        models: models
    }
}


export const loadModels = async (dispatch, getState) => {
    try {
      let userModels = await ModelService.getAllModels();
      
      userModels = userModels.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      
      dispatch({ type: SET_MODELS, models: userModels })
    } catch (err) {
      //
    }
}

export const loadNewModels = async (dispatch, getState) => {
    const { models } = getState().model;

    try {
      let userModels = await ModelService.getAllModels();
      
      userModels = userModels.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .filter((model) => !models.includes(model))
      
      dispatch({ type: ADD_MODELS, models: userModels })
    } catch (err) {
      //
    }
}


const actions = {
    setModels,
    addModels,

    loadModels,
    loadNewModels
}

export default actions