import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'

// Store
import actions from "../../store/actions";

// Services
import FileUploadService from "../../services/fileupload.service";
import ImageService from "../../services/image.service";
import JobService from "../../services/job.service";
import AuthService from "../../services/auth.service";

// Components
import { HeaderSpacer, ImageGallery, Button } from '../../common/common';
import "./UploadNormal.css";

// Assets
import icon_cloud from "../../assets/icons/upload-1.svg";

/* UploadNormal */
const UploadNormal = () => {
  const dispatch = useDispatch();

  // jobs
  const activeJobs = useSelector(state => state.job.trainingJobs);
  const [progress, setProgress] = useState(0);
  const [progressEnabled, setProgressEnabled] = useState(false);

  // input
  const [inputElement, setInputElement] = useState(null); // input element

  // images
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const progressInfosRef = useRef(null);
  const [message, setMessage] = useState([]);
  const [anyImageProcessing, setAnyImageProcessing] = useState(true);

  // image gallery
  const images = useSelector(state => state.image.uploadedImages ? state.image.uploadedImages : []);

  const loadMore = () => {
    dispatch(actions.imageActions.loadNextPageUploaded);
  }

  const upload = (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return FileUploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      )
      setProgressInfos({ val: _progressInfos });
    }, true)
      .then((res) => {
        setMessage((prevMessage) => [
          "Uploaded the image successfully"
        ])

        setTimeout(() => {dispatch(actions.imageActions.loadFirstPageUploaded)}, 2000)
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });

        setMessage((prevMessage) => [
          "Could not upload the image",
        ])
      })
  }

  const uploadImages = (event) => {
    setAnyImageProcessing(true)

    const files = Array.from(event.target.files);

    let _progressInfos = files.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }))

    progressInfosRef.current = {
      val: _progressInfos,
    }

    const uploadPromises = files.map((file, i) => upload(i, file))

    Promise.all(uploadPromises)
      .then(() => {
        console.log("finished uploading files")
      })
  }

  const deleteImage = async (id) => {
    console.log("deleting image with id: " + id)

    dispatch(actions.imageActions.deleteUplaoedImages([id]))
    ImageService.deleteImage(id)
  }

  const createModel = async () => {
    console.log("creating model")

    // pick 25 random images from the uploaded images
    const randomImages = images.sort(() => 0.5 - Math.random()).slice(0, 25).map(image => image.key)
    const baseModel = '6429eb3ea5087f68a10a0659' //'realisticVisionV20_v20.safetensors'
    const output = Math.random().toString(36).substring(2, 15)
    const numSteps = 2000

    // create model
    try {
      let body = { 
        base_model: baseModel,
        options: {
          input_images: randomImages, // training images
          output_name: output, // output model name
          max_train_steps: numSteps // training steps
        }
      }

      const response = await JobService.startTraining(
        body
      )
      
      setProgressEnabled(true)
      dispatch(actions.jobActions.addTrainingJobs([response.data]))

      // refresh the user to get their new tokens
      const user = await AuthService.update()
      dispatch(actions.userActions.login(user))
    } catch (err) {
      console.log(err)
    }
  }

  // refresh active jobs on a timer
  useEffect(() => {
    if(activeJobs && activeJobs.length > 0) {
      const interval = setInterval(async () => {
        // refresh
        dispatch(actions.jobActions.loadTrainingJobs)
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [activeJobs, dispatch])

  // if there are any preprocessing images, refresh on a timer
  useEffect(() => {
    if(images && images.length > 0) {
      // if any of the images are preprocessing
      if(images.filter(image => image.preprocessing).length > 0) {
        setAnyImageProcessing(true)

        const interval = setInterval(async () => {
          // refresh
          dispatch(actions.imageActions.loadFirstPageUploaded)
        }, 5000);
        return () => clearInterval(interval);
      } else {
        setAnyImageProcessing(false)
      }
    }
  }, [images, dispatch])

  // update progress bar
  useEffect(() => {
    const updateProcessingBar = () => {
      if(activeJobs) {
        // check all active jobs
        let job_percents = []
          
        for(let i=0; i < activeJobs.length; i++) {
          let job = activeJobs[i]
          let job_percent = 0.0

          // get job status
          if(job.state === "succeeded") { 
            
            // remove job from active jobs
            dispatch(actions.jobActions.removeTrainingJobs([job._id]))

            job_percent = 1.0
          } else if(job.state === "failed" || job.state === "cancelled" || job.state === "error") {
            // remove job from active jobs
            dispatch(actions.jobActions.removeTrainingJobs([job._id]))

            job_percent = 1.0
          } else {
            job_percent = ((new Date()).getTime() - ((new Date(job.createdAt)).getTime()))/(1000 * 60 * 40)
            job_percent = Math.min(job_percent, 1.0)
          }

          job_percents.push(job_percent)
        }

        // update progress
        const new_percent = job_percents.reduce((a, b) => a + b, 0.0) / parseFloat(job_percents.length)
        setProgress(new_percent)
        setProgressEnabled(job_percents.length > 0)

        // if no active jobs, make sure progress is not shown
        if(job_percents.length === 0) {
          setProgressEnabled(false)
        }
      } else {
        setProgressEnabled(false)
      }
    }

    updateProcessingBar()
    const interval = setInterval(async () => {
      updateProcessingBar()
    }
    , 1000);
    return () => clearInterval(interval);
  }, [activeJobs, dispatch])

  return (
    <div className="uploadnormal">
      <HeaderSpacer />
      { progressEnabled &&
      <div className="uploadnormal-overlay">
        <div className="uploadnormal-overlay-modal">
          <div className="uploadnormal-overlay-modal-header">The model is getting ready...</div>
          <div className="uploadnormal-overlay-modal-text">It may take 30 minutes. We will notify you by mail so you don't miss anything.</div>
          <div className="uploadnormal-overlay-modal-progress-bar">
            <div className="uploadnormal-overlay-modal-progress-bar-progress" style={{ 'width': `${progress * 100}%`}}>
              <div className="uploadnormal-overlay-modal-progress-bar-progress-sliver" />
            </div>
          </div>
          <div className="uploadnormal-overlay-modal-progress-text">{`${((progress ? progress : 0)*100).toFixed(0)}%`}</div>
        </div>
      </div>
      }
      <div className="uploadnormal-container">
        <div className='uploadnormal-left'>
          <Button 
            className="uploadnormal-add-photos"
            icon={icon_cloud}
            text="Add more photos..."
            color="#8D1DFF"
            onClick={() => inputElement.click()} /
          >
          <div className="uploadnormal-callout-left">{message}</div>
           <input
              ref={input => setInputElement(input)}
              style={{ display: 'none'}}
              type="file"
              multiple
              accept="image/*"
              onChange={(e) => {
                uploadImages(e)
              }}
            />
        </div>
        <div className='uploadnormal-right'>
          <Button 
            className="uploadnormal-process-photos"
            text="Process (500 credits)"
            highlight={anyImageProcessing ? false : true}
            onClick={() => {
              if(!anyImageProcessing) {
                createModel()
              }
            }} /
          >
          <div className="uploadnormal-callout-right"><span className="uploadnormal-exclamation-image" />Please be aware that photo generation may take more than 30 minutes.</div>
        </div>
      </div>
      <ImageGallery images={images} onClick={deleteImage} loadMore={loadMore} />
    </div>
  );
};

export default UploadNormal;