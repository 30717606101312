import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'

// Store
import actions from "../../store/actions";

// Components
import UploadNormal from "./UploadNormal";
import UploadBlank from "./UploadBlank";

const Upload = () => {
  const dispatch = useDispatch();

  // uploaded images (non-generated)
  const images = useSelector(state => state.image.uploadedImages);

  // only fetch a new page if we don't have any images yet (we don't want to overwrite existing images)
  useEffect(() => {
    if(!images || images.length === 0) dispatch(actions.imageActions.loadFirstPageUploaded);
  }, []);

  return (
    images ? images.length > 0 ? <UploadNormal /> : <UploadBlank /> : <div style={{ 'width': '100%', 'backgroundColor': 'black' }}>Loading...</div>
  );
};

export default Upload;